import {Container} from 'inversify';
import getDecorators, { fixInjectedProperties } from './decorators';
import {
    AlertManagerService,
    FormValidatorService,
    StepManagerService,
    InquiryFormUpdateService,
    AuthenticationFlowService,
    FormConfigTranslationService
} from '../service';

const container = new Container();

container.bind<AlertManagerService>('AlertManagerService').to(AlertManagerService).inSingletonScope();
container.bind<FormValidatorService>('FormValidatorService').to(FormValidatorService).inSingletonScope();
container.bind<StepManagerService>('StepManagerService').to(StepManagerService).inSingletonScope();
container.bind<InquiryFormUpdateService>('InquiryFormUpdateService').to(InquiryFormUpdateService).inSingletonScope();
container.bind<AuthenticationFlowService>('AuthenticationFlowService').to(AuthenticationFlowService).inSingletonScope();
container.bind<FormConfigTranslationService>('FormConfigTranslationService').to(FormConfigTranslationService).inSingletonScope();

const { lazyInject } = getDecorators(container);

export { container, lazyInject, fixInjectedProperties };
