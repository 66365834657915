import * as React from "react";
import {Form, Translation, FormControlChangeType, IFormConfig} from 'common-web';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {inquiryFormSelector} from "../../../store/selectors/axaInsuranceSelectors";
import {changeCurrentStep, changeInquiryForm, IInquiryForm} from "../../../store/reducers/axaInsuranceSlice";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {patientFormConfig} from "./patientFormConfig";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {InquiryFormStep} from '../inquiryFormStep';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {IStepManagerService} from '../../../service/stepManagerService';
import {patientCompanionControls} from './patientCompanionControls';
import {BehaviorSubject, Subscription} from "rxjs";
import {tap, filter, debounceTime} from "rxjs/operators";
import {IFormValidatorService} from '../../../service/formValidatorService';
import {IFormConfigTranslationService} from "../../../service/formConfigTranslationService";
import ResidencyInformationModal from "./ResidencyInformationModal";

interface IFormStepPatientProps extends RouteComponentProps {
    readonly inquiryForm: IInquiryForm;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly changeInquiryForm: typeof changeInquiryForm;
}

interface IFormStepPatientState {
    formConfig: typeof IFormConfig;
    value: any;
    isFormValid: boolean;
    addCompanion: boolean | null;
    residencyInformationModalShown: boolean;
}

class FormStepPatient extends React.Component<IFormStepPatientProps, IFormStepPatientState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('FormValidatorService') private formValidator: IFormValidatorService;
    @lazyInject('FormConfigTranslationService') private formConfigTranslator: IFormConfigTranslationService;


    readonly stepName: InquiryFormStep = InquiryFormStep.PATIENT;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    private isProcessing: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            formConfig: patientFormConfig,
            value: null,
            isFormValid: true,
            addCompanion: null,
            residencyInformationModalShown: false,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        const translatedFormConfig = this.formConfigTranslator.setTranslationKeys(this.state.formConfig, this.stepName);
        this.setState({formConfig: translatedFormConfig});
        this.props.changeCurrentStep(this.stepName);
        if (this.props.inquiryForm) {
            this.updateFormFromState();
        }

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap(()  => this.isProcessing = true),
                debounceTime(500),
                tap((data: any)  => this.onFormValueChange(data.value)),
                tap(()  => this.isProcessing = false),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<IFormStepPatientProps>,
        prevState: Readonly<IFormStepPatientState>,
        snapshot?: any
    ): void {
        if (this.props.inquiryForm !== prevProps.inquiryForm) {
            this.updateFormFromState();
        }

        if ((this.state.value?.residency !== prevState.value?.residency && this.state.value?.residency === 'GB') ||
            (this.state.value?.companionResidency !== prevState.value?.companionResidency && this.state.value?.companionResidency === 'GB')) {
            this.toggleResidencyInformationModal();
        }
    }

    componentWillUnmount(): void {
        if (true === this.state.addCompanion) {
            this.restoreDefaultFormConfig();
        }
    }

    render() {
        return (
            <React.Fragment>
                <header>
                    <h2 className={'sr-only'}><Translation text={`form.axaForm.patient.title`}/></h2>
                </header>
                <article>
                    <header>
                        <h3 className={'sr-only'}><Translation text={`form.axaForm.patient.subtitle`}/>
                        </h3>
                    </header>
                    <Form config={this.state.formConfig}
                          onValueStateChange={this.onValueStateChange}
                          onValidationStateChange={this.onValidationStateChange}
                          value={this.state.value}
                          controlName={'patientForm'}/>

                    <footer className="button-form-container">
                        <button onClick={this.goPrev}
                                className="btn btn-prev">
                            <Translation text={`form.buttons.prev`}/>
                        </button>

                        <button className={`btn btn-next ${!this.state.isFormValid ? 'disabled' : ''}`}
                                tabIndex={!this.state.isFormValid ? -1 : 0}
                                onClick={this.goNext}>
                            <Translation text={`form.buttons.next`}/>
                        </button>
                    </footer>
                </article>

                {this.state.residencyInformationModalShown ?
                    <ResidencyInformationModal isModalShown={this.state.residencyInformationModalShown}
                                               toggleResidencyInformationModal={this.toggleResidencyInformationModal}/> : null}
            </React.Fragment>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        const values = this.state.value;
        this.setState({isFormValid: isValid && values?.residency !== 'GB' && values?.companionResidency !== 'GB'});
    };

    private goNext = () => {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.stepManager.goNext(this.stepName)
    };

    private goPrev = () => {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.stepManager.goPrev(this.stepName)
    };

    // ========== CUSTOM METHODS ========== //


    private onFormValueChange = (value: any) => {
        this.props.changeInquiryForm(value);

        Object.keys(value).map((key: string) => {
            if (key === 'addCompanion' && value[key] !== null && value[key] !== this.state.addCompanion) {
                if (value[key] === true) {
                    this.addCompanionFields(value);
                    return;
                }
                if (value[key] === false) {
                    this.removeCompanionFields(value);
                }
            }
        });
    };

    private updateFormFromState = () => {
        const inquiry = this.props.inquiryForm;
        let addCompanion = inquiry.addCompanion;

        const value: any = {
            residency: inquiry.residency,
            birthDate: inquiry.birthDate,
            name: inquiry.name,
            lastName: inquiry.lastName,
            city: inquiry.city,
            postCode: inquiry.postCode,
            street: inquiry.street,
            addCompanion: inquiry.addCompanion
        };

        if (inquiry.addCompanion) {
            value['companionName'] = inquiry.companionName;
            value['companionLastName'] = inquiry.companionLastName;
            value['companionBirthDate'] = inquiry.companionBirthDate;
            value['companionResidency'] = inquiry.companionResidency;
        }
        if (addCompanion !== this.state.addCompanion) {
            if (true === addCompanion) {
                this.addCompanionFields(value);
            } else if (false === addCompanion && null !== this.state.addCompanion) {
                // state.addCompanion not null check to not remove fields on form init
                this.removeCompanionFields(value);
            }
        }

        this.setState({
            value: value,
            addCompanion,
        });
    };

    private addCompanionFields(value: any): void {
        const controls = Array.from(this.state.formConfig.controls);
        const cloneFormConfig = Object.assign({}, patientFormConfig);
        cloneFormConfig['controls'] = [...controls, ...patientCompanionControls];

        const cloneValue = Object.assign({}, value);

        cloneValue['companionName'] = this.props.inquiryForm.companionName;
        cloneValue['companionLastName'] = this.props.inquiryForm.companionLastName;
        cloneValue['companionBirthDate'] = this.props.inquiryForm.companionBirthDate;
        cloneValue['companionResidency'] = this.props.inquiryForm.companionResidency;
        this.formConfigTranslator.setTranslationKeys(cloneFormConfig, this.stepName);

        cloneFormConfig['controls'] = Object.values(cloneFormConfig['controls'].reduce((acc: any, cur: any) => {
            acc[cur.key] = acc[cur.key] || cur;
            return acc;
        }, {}));

        this.setState({
            formConfig: cloneFormConfig,
            value: cloneValue,
            addCompanion: true,
        });

        setTimeout(() => {
            patientFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }

    private removeCompanionFields(value: any): void {
        const controls = Array.from(this.state.formConfig.controls);
        const cloneFormConfig = Object.assign({}, patientFormConfig);
        cloneFormConfig['controls'] = controls.slice(0, controls.length - 2);

        const cloneValue = Object.assign({}, value);

        delete cloneValue.companionName;
        delete cloneValue.companionLastName;
        delete cloneValue.companionBirthDate;
        delete cloneValue.companionResidency;

        this.setState({
            formConfig: cloneFormConfig,
            value: cloneValue,
            addCompanion: false,
        });
        setTimeout(() => {
            patientFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }

    private restoreDefaultFormConfig(): void {
        const controls = Array.from(this.state.formConfig.controls);
        const cloneFormConfig = Object.assign({}, patientFormConfig);
        cloneFormConfig['controls'] = controls.slice(0, controls.length - 2);
        setTimeout(() => {
            patientFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }

    private toggleResidencyInformationModal = () => {
        this.setState({residencyInformationModalShown: !this.state.residencyInformationModalShown});
    };
}


export default connect(
    (state: RootState) => ({
        inquiryForm: inquiryFormSelector(state),
    }),
    {
        changeCurrentStep,
        changeInquiryForm,
    })(withRouter(FormStepPatient));
