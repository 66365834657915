import * as React from 'react';
import {Translation} from 'common-web';
import {connect} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {
    isClinicHostSelector,
    currentStepSelector,
    inquiryFormSelector,
    inquiryIdSelector,
    inquirySelector,
    isInquiryFormUpdatedSelector,
    showFormLoaderSelector,
    isClinicStepsFormExpandedSelector
} from '../../../store/selectors/axaInsuranceSelectors';
import {
    changeCurrentStep,
    changeInquiry,
    changeInquiryId,
    changeIsInquiryFormUpdated,
    changeShowFormLoader,
    IInquiryForm,
} from '../../../store/reducers/axaInsuranceSlice';
import {setSecret} from '../../../store/reducers/authSlice';
import {authTokenSelector, secretSelector} from '../../../store/selectors/authSelectors';
import {fixInjectedProperties, lazyInject} from '../../../ioc';
import {IAlertManagerService} from '../../../service/alertManagerService';
import {InquiryFormStep} from '../inquiryFormStep';
import QuoteList, {IQuoteTableItem} from './QuoteList';
import {QuoteTableConfigFactory} from './quoteTableConfigFactory';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {of, Subscription, throwError} from 'rxjs';
import {InquiryAPIPersister} from '../Utils/inquiryAPIPersister';
import {IStepManagerService} from '../../../service/stepManagerService';
import {IInquiryURLParams} from '../../../model/iInquiryURLParams';
import {RouteComponentProps} from 'react-router-dom';
import {IFormConfigTranslationService} from "../../../service/formConfigTranslationService";
import {activeLanguageSelector} from "../../../store/selectors/sagaSelectors";
import {LanguageLocale} from "../../../constants/locales";

interface IFormStepQuoteProps extends RouteComponentProps {
    readonly currentStep: InquiryFormStep;
    readonly inquiryForm: IInquiryForm;
    readonly inquiryId: string | null;
    readonly authToken: string;
    readonly secret: string;
    readonly showFormLoader: boolean,
    readonly changeShowFormLoader: typeof changeShowFormLoader;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly inquiry: any;
    readonly isClinicHost: boolean;
    readonly isClinicStepsFormExpanded: boolean;
    readonly isInquiryFormUpdated: boolean;
    readonly activeLanguage: LanguageLocale;
    readonly changeIsInquiryFormUpdated: typeof changeIsInquiryFormUpdated;
    readonly changeInquiryId: typeof changeInquiryId;
    readonly changeInquiry: typeof changeInquiry;
    readonly setSecret: typeof setSecret;
}

interface IFormStepQuoteState {
    quoteTableConfig: IQuoteTableItem[];
}

class FormStepQuote extends React.Component<IFormStepQuoteProps, IFormStepQuoteState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('FormConfigTranslationService') private formConfigTranslator: IFormConfigTranslationService;

    readonly stepName: InquiryFormStep = InquiryFormStep.QUOTE;
    readonly subscriptions: Subscription[] = [];

    constructor(props: any) {
        super(props);
        this.state = {
            quoteTableConfig: [],
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.props.changeCurrentStep(this.stepName);
        this.props.changeShowFormLoader(true);

        const {inquiryId} = this.props.match.params as IInquiryURLParams;
        if (inquiryId && !this.props.isInquiryFormUpdated) {
            this.subscriptions.push(this.fetchInquiry(inquiryId).subscribe())
        } else {
            this.subscriptions.push(
                this.persistInquiry(inquiryId, (null === inquiryId || undefined === inquiryId)).subscribe()
            );
            if (this.needsToClearSecret(inquiryId)) {
                this.props.setSecret(null);
            }
        }
    }

    componentWillUnmount() {
        this.props.changeShowFormLoader(false);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <header>
                    <h2 className={'sr-only'}><Translation text={`form.axaForm.quote.title`}/></h2>
                </header>
                <QuoteList items={this.state.quoteTableConfig}/>
                <footer className="button-form-container">
                    <button onClick={this.goPrev}
                            className="btn btn-prev">
                        <Translation text={`form.buttons.prev`}/>
                    </button>

                    { this.renderNextButton() }

                </footer>
            </React.Fragment>
        );
    }

    private goNext = () => {
        this.props.changeIsInquiryFormUpdated(false);
        this.stepManager.goNext(this.stepName)
    };

    private goPrev = () => {
        this.stepManager.goPrev(this.stepName)
    };

    // ========== CUSTOM METHODS ========== //

    private renderNextButton() {
        if (this.props.isClinicHost && !this.props.isClinicStepsFormExpanded) {
            return null;
        }

        return (
            <button
                className="btn btn-next"
                tabIndex={0}
                onClick={this.goNext}>
                <Translation text={`form.buttons.next`}/>
            </button>
        )
    }

    private updateQuoteFromAPI = (apiData: any) => {
        const quoteTableConfig = (new QuoteTableConfigFactory()).create(this.props.inquiryForm, apiData);
        const translatedQuoteTableConfig = this.formConfigTranslator.setQuoteListTranslationKeys(quoteTableConfig, this.stepName);
        this.setState({
           quoteTableConfig: translatedQuoteTableConfig,
        });
    };

    private persistInquiry(inquiryId: string | null, isCreate: boolean) {
        // do not attach authToken when creating a new Inquiry in ClinicHost, however
        // attach authToken when clinic buys insurance for patient
        const isInsuranceForPatient = this.props.isClinicHost && this.props.isClinicStepsFormExpanded,
            shouldAttachCredentials = (inquiryId || !this.props.isClinicHost),
            credentials = isInsuranceForPatient ? {authToken: this.props.authToken, secret: this.props.secret} :
                shouldAttachCredentials ? this.credentials : {secret: this.props.secret};

        return this.persister.persist(inquiryId, this.props.inquiryForm,  credentials, this.props.inquiry)
            .pipe(
                tap(retrievedInquiryId => {
                    if (isCreate) {
                        this.props.changeInquiryId(retrievedInquiryId);
                        const secretParam: string = this.props.isClinicHost ? `?s=${this.props.secret}` : '';
                        const lng: string = !this.props.isClinicHost ? `/${this.props.activeLanguage}` : '';
                        const url = `${lng}/inquiry/quote/${retrievedInquiryId}${secretParam}`;
                        window.parent.postMessage(url, '*');
                        window.history.replaceState(null, '', url);
                    }
                }),
                catchError((error) => {
                    this.alertManager.handleFormApiError(error.response);
                    if (isCreate) {
                        this.stepManager.goTo(InquiryFormStep.PATIENT);
                    }
                    return throwError(error);
                }),
                tap(() => {
                    this.props.changeIsInquiryFormUpdated(false)
                }),
                switchMap(retrievedInquiryId => this.fetchInquiry(retrievedInquiryId))
            );
    }

    private fetchInquiry(inquiryId: string) {
        const credentials = this.props.isClinicHost && !this.props.isClinicStepsFormExpanded ? {secret: this.props.secret} : this.credentials;
        return this.persister.fetch(inquiryId, credentials).pipe(
            tap(data => {
                this.updateQuoteFromAPI(data);
                this.props.changeInquiry(data);
                const clinicLocationUrl: string = this.props.isClinicHost ?
                    `/inquiry/quote/${inquiryId}?s=${this.props.secret}` :
                    `/${this.props.activeLanguage}/inquiry/quote/${inquiryId}`;
                window.parent.postMessage(clinicLocationUrl, '*');

            }),
            catchError(error => {
                this.alertManager.addAlert(`${error.response} You are not allowed to view this Inquiry, contact your Clinic administrator`);
                this.alertManager.handleFormApiError(error);
                this.stepManager.goTo(InquiryFormStep.PATIENT);
                return throwError(error);
            }),
            tap(() => {
                this.props.changeIsInquiryFormUpdated(false);
                this.props.changeShowFormLoader(false);
                return of();
            }),
        );
    };

    private needsToClearSecret(inquiryId: string | null): boolean {
        return !this.props.isClinicHost && inquiryId === null && null !== this.props.authToken && undefined !== this.props.authToken;
    }

    private get persister() {
        return new InquiryAPIPersister();
    }

    private get credentials() {
        if (this.props.authToken) {
            return {authToken: this.props.authToken};
        } else if (this.props.secret) {
            return {secret: this.props.secret};
        }
        throw new Error(`Cannot establish correct credentials to persist Inquiry.`);
    }
}

export default connect(
    (state: RootState) => ({
        currentStep: currentStepSelector(state),
        authToken: authTokenSelector(state),
        inquiryForm: inquiryFormSelector(state),
        inquiryId: inquiryIdSelector(state),
        isClinicHost: isClinicHostSelector(state),
        isClinicStepsFormExpanded: isClinicStepsFormExpandedSelector(state),
        secret: secretSelector(state),
        inquiry: inquirySelector(state),
        isInquiryFormUpdated: isInquiryFormUpdatedSelector(state),
        showFormLoader: showFormLoaderSelector(state),
        activeLanguage: activeLanguageSelector(state),
    }),
    {
        changeCurrentStep,
        changeShowFormLoader,
        changeIsInquiryFormUpdated,
        changeInquiryId,
        changeInquiry,
        setSecret,
    }
)(FormStepQuote);
