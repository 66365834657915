import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import { ISagaState } from "../reducers/sagaSlice";

export const selectSaga = (state: RootState): ISagaState => {
  return state.saga;
};

export const activeLanguageSelector = createSelector(
  [selectSaga],
  (sagaI18nState: ISagaState) => sagaI18nState.activeLanguage
);

