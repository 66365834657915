import {IInquiryForm} from "../../../store/reducers/axaInsuranceSlice";
import {IInquirySubject} from "../../../model/iInquirySubject";
import {parseToStartOfDay} from "common-web";

export class InquirySubjectFactory {
    createSubjects(inquiryForm: IInquiryForm, inquiry: any) {
        if (!inquiryForm.name || !inquiryForm.lastName || !inquiryForm.birthDate ||
            !inquiryForm.departure || !inquiryForm.return || !inquiryForm.treatmentCategoryId || !inquiryForm.destinationId ||
            !inquiryForm.street || !inquiryForm.city || !inquiryForm.postCode || !inquiryForm.residency) {
            return;
        }
        const mainInquirySubject: IInquirySubject = {
            firstName: inquiryForm.name,
            lastName: inquiryForm.lastName,
            birthDate: parseToStartOfDay(inquiryForm.birthDate),
            address: {
                addressLine1: inquiryForm.street,
                addressLine2: null,
                addressLine3: null,
                postcode: inquiryForm.postCode,
                city: inquiryForm.city,
                country: inquiryForm.residency
            },
            main: true
        };

        if (inquiry && inquiry.inquirySubjects) {
            const mainSubject = inquiry.inquirySubjects.find((subject: any) => subject.main === true);
            mainInquirySubject['id'] = mainSubject ? mainSubject.id : undefined;
        }

        let inquirySubjectsArray: IInquirySubject[] = [];
        inquirySubjectsArray.push(mainInquirySubject);

        if (inquiryForm.addCompanion &&
            inquiryForm.companionName &&
            inquiryForm.companionLastName &&
            inquiryForm.companionBirthDate &&
            inquiryForm.companionResidency) {
            const companionBirthDate = parseToStartOfDay(inquiryForm.companionBirthDate);
            const companionInquirySubject: IInquirySubject = {
                firstName: inquiryForm.companionName,
                lastName: inquiryForm.companionLastName,
                birthDate: companionBirthDate,
                address: {
                    addressLine1: null,
                    addressLine2: null,
                    addressLine3: null,
                    city: null,
                    country: inquiryForm.companionResidency,
                    postcode: null,
                },
                main: false
            };

            if (inquiry && inquiry.inquirySubjects && inquiry.inquirySubjects.length > 1) {
                const companionSubject = inquiry.inquirySubjects.find((subject: any) => subject.main === false);
                companionInquirySubject['id'] = companionSubject ? companionSubject.id : undefined;
            }

            inquirySubjectsArray.push(companionInquirySubject);
        }

        return inquirySubjectsArray;
    }
}
