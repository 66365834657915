import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";
import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";

export function getInquiriesApi(): Observable<any> {
    return clinicHunterInquiryAPI.get(
        "inquiries",
        new RestQueryParams({page: 1}),
        {
            accept: 'application/ld+json',
            'content-type': 'application/json'
        },
    );
}

export function getInquiryAsAnonymousAPI(inquiryId: string, secret: string): Observable<any> {
    return clinicHunterInquiryAPI.get(
        `inquiries/${inquiryId}`,
        new RestQueryParams(),
        {
            accept: 'application/ld+json',
            'content-type': 'application/json',
            'X-Inquiry-Secret': secret
        },
    );
}
export function getInquiryAsLoggedAPI(inquiryId: string, accessToken: string): Observable<any> {
    return clinicHunterInquiryAPI.get(
        `inquiries/${inquiryId}`,
        new RestQueryParams(),
        {
            accept: 'application/ld+json',
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    );
}

export function getInquiryAPI(inquiryId: string,
                              credentials: {
                                  authToken: string | undefined,
                                  secret: string | undefined,
                              }): Observable<any> {
    let headers: any = {
        accept: 'application/ld+json',
        'content-type': 'application/json',
    };
    if (undefined !== credentials.authToken) {
        headers['Authorization'] = `Bearer ${credentials.authToken}`;
    } else if (undefined !== credentials.secret) {
        headers['X-Inquiry-Secret'] = credentials.secret;
    }

    return clinicHunterInquiryAPI.get(
        `inquiries/${inquiryId}`,
        new RestQueryParams(),
        headers
    );
}
