import * as React from "react";
import {Form, FormControlChangeType, IFormConfig, Translation} from 'common-web';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {inquiryFormSelector, inquiryIdSelector} from "../../../store/selectors/axaInsuranceSelectors";
import {
    changeCurrentStep,
    changeInquiryForm,
    changeShowFormLoader,
    IInquiryForm
} from "../../../store/reducers/axaInsuranceSlice";
import {customSourceControls, registerFormConfig} from '../authFormConfigs';
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {InquiryFormStep} from '../../InquiryForm/inquiryFormStep';
import {IStepManagerService} from '../../../service/stepManagerService';
import {IAuthenticationFlowService} from "../../../service/authenticationFlowService";
import {BehaviorSubject, Subscription, throwError} from "rxjs";
import {catchError, debounceTime, filter, tap} from "rxjs/operators";

interface IRegisterComponentProps {
    readonly inquiryForm: IInquiryForm;
    readonly inquiryId: string | null;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly changeShowFormLoader: typeof changeShowFormLoader;
    readonly changeInquiryForm: typeof changeInquiryForm;
}

interface IRegisterComponentState {
    formConfig: typeof IFormConfig;
    value: any;
    isFormValid: boolean;
    isCustomSourceField: boolean;
}

class RegisterComponent extends React.Component<IRegisterComponentProps, IRegisterComponentState> {
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('AuthenticationFlowService') private authenticationFlow: IAuthenticationFlowService;

    readonly stepName: InquiryFormStep = InquiryFormStep.ACCOUNT;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    private isProcessing: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            formConfig: registerFormConfig,
            value: null,
            isFormValid: false,
            isCustomSourceField: false,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap(() => this.isProcessing = true),
                debounceTime(500),
                tap((data: any) => this.onFormValueChange(data.value)),
                tap(() => this.isProcessing = false),
            ).subscribe()
        );
    }

    componentWillUnmount() {
        this.props.changeShowFormLoader(false);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <Form config={this.state.formConfig}
                      onValidationStateChange={this.onValidationStateChange}
                      onValueStateChange={this.onValueStateChange}
                      controlName={"registerForm"}
                      value={this.state.value}/>
                <footer className="button-form-container">
                    <button onClick={this.goPrev}
                            className="btn btn-prev">
                        <Translation text={`form.buttons.prev`}/>
                    </button>
                    <button className={`btn btn-next ${!this.state.isFormValid ? 'disabled' : ''}`}
                            type="button" name="register"
                            tabIndex={!this.state.isFormValid ? -1 : 0}
                            disabled={!this.state.isFormValid}
                            onClick={this.register}>
                        <Translation text={`form.buttons.next`}/>
                    </button>
                </footer>
            </React.Fragment>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private goPrev = () => {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.stepManager.goPrev(this.stepName)
    };

    // ========== CUSTOM METHODS ========== //

    private onFormValueChange = (value: any) => {
        const mappedValue: any = {};
        Object.keys(value).forEach((key: string) => {
            let fieldValue = value[key];

            if (undefined === fieldValue || null === fieldValue) {
                mappedValue[key] = null;

                return;
            }

            if (key === 'source') {
                if (value[key] === 'other' && !this.state.isCustomSourceField) {
                    this.addCustomSourceField();
                }

                if (value[key] !== 'other' && this.state.isCustomSourceField) {
                    this.removeCustomSourceFields();
                    delete mappedValue['customSource'];
                }
            }
            mappedValue[key] = fieldValue;
        })

        if (mappedValue.hasOwnProperty('customSource') && mappedValue.hasOwnProperty('source')) {
            if (mappedValue.source === 'other') {
                mappedValue.source = mappedValue.customSource;
                delete mappedValue.customSource;
            }

            if (mappedValue.source !== 'other') {
                delete mappedValue.customSource;
            }
        }

        this.props.changeInquiryForm(mappedValue);
    };

    private register = (e: any): any => {
        e.preventDefault();
        const registrationEmail = this.props.inquiryForm.registrationEmail,
            registrationPassword = this.props.inquiryForm.registrationPassword;

        if (this.isProcessing || !this.state.isFormValid || !registrationEmail || !registrationPassword) {
            return;
        }
        this.isProcessing = true;
        this.subscriptions.push(
            this.authenticationFlow.submitRegistration(registrationEmail, registrationPassword, true).pipe(
                catchError((error) => {
                    this.isProcessing = false;
                    return throwError(error);
                }),
            ).subscribe()
        );
    };

    private addCustomSourceField(): void {
        const controls = Array.from(this.state.formConfig.controls),
            cloneFormConfig = Object.assign({}, registerFormConfig);
        cloneFormConfig['controls'] = [...controls, ...customSourceControls];

        this.setState({
            formConfig: cloneFormConfig,
            isCustomSourceField: true,
        });
        setTimeout(() => {
            registerFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }

    private removeCustomSourceFields(): void {
        const controls = Array.from(this.state.formConfig.controls),
            cloneFormConfig = Object.assign({}, registerFormConfig);
        cloneFormConfig['controls'] = controls.slice(0, controls.length - 1);

        this.setState({
            formConfig: cloneFormConfig,
            isCustomSourceField: false,
        });
        setTimeout(() => {
            registerFormConfig['controls'] = cloneFormConfig['controls'];
        }, 0);
    }
}

export default connect(
    (state: RootState) => ({
        inquiryForm: inquiryFormSelector(state),
        inquiryId: inquiryIdSelector(state),
    }),
    {
        changeCurrentStep,
        changeShowFormLoader,
        changeInquiryForm,
    }
)(RegisterComponent);

