import {IInquiryForm} from "../../../store/reducers/axaInsuranceSlice";
import {InquirySubjectFactory} from "../FormStepQuote/inquirySubjectFactory";
import {createInquiryAPI} from "../../../api/createInquiryAPI";
import {map, mapTo} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {updateInquiryAPI} from "../../../api/updateInquiryAPI";
import {getInquiryAPI} from "../../../api/getInquiry";
import {parseToEndOfDay, parseToStartOfDay} from "common-web";

export class InquiryAPIPersister {

    fetch(inquiryId: string, credentials: any) {
        return getInquiryAPI(inquiryId, credentials);
    }

    persist(id: string | null, form: IInquiryForm, credentials: any, inquiry: any): Observable<string> {
        const inquirySubjectsArray = (new InquirySubjectFactory()).createSubjects(form, inquiry);
        if (!inquirySubjectsArray) {
            return of();
        }
        const data: [any, any, any, any, any, any, any, any, any, any, any, any, any] = [
                credentials,
                parseToStartOfDay(form.departure),
                parseToEndOfDay(form.return),
                form.treatmentCategoryId as string,
                form.destinationId as string,
                inquirySubjectsArray,
                form.cid ? form.cid : null,
                form.clinicName ? form.clinicName : null,
                form.patientEmail ? form.patientEmail : null,
                form.visitDate ? form.visitDate : null,
                form.source ? form.source : null,
            ] as any;
        if (null === id || undefined === id) {
            return createInquiryAPI(...data).pipe(map(response => response.id));
        }

        return updateInquiryAPI(id, ...data).pipe(mapTo(id));
    }
}
