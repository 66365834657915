import {injectable} from 'inversify';
import {addAlert} from 'common-web';
import {fixInjectedProperties} from '../ioc';
import store from '../store';

export enum AlertType {
    INFO = "info",
    WARNING = "warning",
    SUCCESS = "success",
    ERROR = "error",
}

export interface IAlertManagerService {
    handleFormApiError(error?: any, type?: AlertType): void;
    handlePaymentSuccess(): void;
    registrationSuccess(): void;
    paymentProcessing(): void;
    loginSuccess(): void;
    loginFailure(): void;
    logoutSuccess(): void;
    addAlert(message: string, type?: AlertType | null, displayFor?: number | null, isTranslated?: boolean): void;
}

@injectable()
class AlertManagerService implements IAlertManagerService {

    constructor() {
        fixInjectedProperties(this);
    }

    public handleFormApiError(error: any = null, type: AlertType = AlertType.WARNING) {
        let message = error ? error : 'alerts.baseError';

        if (error) {
            if (error['hydra:description']) {

                if (error['hydra:description'] === 'You cant set accepted offer') {
                    message = 'alerts.inquiryAlreadyPurchased'
                } else {
                    message = error['hydra:description']
                }
            } else if (error.message) {
                if (error.message === 'JWT Token not found') {
                    message = 'alerts.unauthorized';
                } else {
                    message = error.message
                }
            }
        }

        this.addAlert(message, type, null, true);
    };

    public handlePaymentSuccess() {
        this.addAlert('alerts.paymentComplete', AlertType.INFO, null, true);
    };

    public paymentProcessing() {
        this.addAlert('alerts.paymentIsProcessing', AlertType.INFO, null, true);
    };

    public registrationSuccess() {
        this.addAlert('alerts.registrationSuccess', AlertType.INFO, null, true);
    };

    public loginSuccess() {
        this.addAlert('alerts.loginSuccess', AlertType.INFO, null, true);
    };

    public loginFailure() {
        return this.addAlert('alerts.loginFailed', AlertType.WARNING, null, true);
    };

    public logoutSuccess() {
        return this.addAlert('alerts.logoutSuccess', AlertType.INFO, null, true);
    }

    public addAlert(
        message: string,
        type: AlertType | null = AlertType.INFO,
        displayFor: number | null = 5000,
        isTranslated: boolean = true
    ) {

        const alert = {
            message: message,
            type: type,
            displayFor: displayFor,
            isTranslated: isTranslated
        };
        store.dispatch(addAlert(alert));
    }
}

export default AlertManagerService;
