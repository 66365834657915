import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterTreatmentsAPI} from "./provider/clinicHunterTreatmentTypes";

export function getTreatmentsAPI(): Observable<any> {
    return clinicHunterTreatmentsAPI.get(
        "treatment_categories",
        new RestQueryParams({page: 1}),
        {
            accept: 'application/ld+json',
            'content-type': 'application/json'
        },
    );
}

export function getTreatmentAPI(treatmentTypeId: string): Observable<any> {
    return clinicHunterTreatmentsAPI.get(
        `treatment-type/${treatmentTypeId}`,
        new RestQueryParams(),
        {
            accept: 'application/ld+json',
            'content-type': 'application/json'
        },
    );
}
