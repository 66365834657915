import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";
import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";

export function assignInquiryToUserAPI(
    inquiryId: string,
    accessToken: string,
    secret: string,
): Observable<any> {
    return clinicHunterInquiryAPI.put(
        `inquiry/${inquiryId}/assign_inquiry_to_user`,
        {},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
            'X-Inquiry-Secret': secret,
        },
    );
}
