import {injectable} from 'inversify';
import store from '../store';
import {InquiryFormStep} from '../components/InquiryForm/inquiryFormStep';

export interface IFormValidatorService {
    isStepValid: (insuranceFormStep: InquiryFormStep) => boolean;
}

@injectable()
class FormValidatorService implements IFormValidatorService {

    public isStepValid(insuranceFormStep: InquiryFormStep) {
        const globalState = store.getState(),
            inquiryForm = globalState.axaInsurance.inquiryForm;

        switch (insuranceFormStep) {
            case InquiryFormStep.TREATMENT:
                return (
                    inquiryForm.destinationId !== null &&
                    inquiryForm.treatmentCategoryId !== null &&
                    inquiryForm.departure !== null &&
                    inquiryForm.return !== null
                );

            case InquiryFormStep.PATIENT:
                let isCompanionValid = true;
                if(inquiryForm.addCompanion) {
                    isCompanionValid = inquiryForm.companionName !== null &&
                                       inquiryForm.companionLastName !== null &&
                                       inquiryForm.companionBirthDate !== null &&
                                       inquiryForm.companionResidency !== null;
                }
                return (
                    inquiryForm.residency !== null &&
                    inquiryForm.birthDate !== null &&
                    inquiryForm.name !== null &&
                    inquiryForm.street !== null &&
                    inquiryForm.city !== null &&
                    inquiryForm.postCode !== null &&
                    inquiryForm.addCompanion !== null &&
                    isCompanionValid
                );

            case InquiryFormStep.QUOTE:
                return true;

            case InquiryFormStep.ACCOUNT:
                return true;

            case InquiryFormStep.PATIENT_EMAIL:
                return inquiryForm.patientEmail !== null;

            case InquiryFormStep.PAYMENT:
                return (
                    inquiryForm.cardholderName !== null &&
                    inquiryForm.specialTermsAndConditions !== null &&
                    inquiryForm.needsAndRequirements !== null &&
                    inquiryForm.distanceContracts !== null &&
                    globalState.auth.isAuthenticated
                );

            case InquiryFormStep.SUCCESS:
                return true;

            default:
                throw new Error(`Invalid form step: ${insuranceFormStep}`)

        }
    }
}

export default FormValidatorService;
