import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import { IAxaInsuranceState } from "store/reducers/axaInsuranceSlice";

export const selectAxaInsurance = (state: RootState): IAxaInsuranceState => {
    return state.axaInsurance;
};

export const currentStepSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.currentStep
);

export const formStepsSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.formSteps
);

export const inquiryFormSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.inquiryForm
);

export const isInquiryCreatedSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.isInquiryCreated
);

export const inquiryIdSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.inquiryId
);

export const resetInvalidInquiryFormInputSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.inquiryForm
);

export const showFormLoaderSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.showFormLoader
);

export const isFormInitializedSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.initialized
);

export const wizardHeadStepIndexSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.wizardHeadStepIndex
);

export const isInquiryFormUpdatedSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.isInquiryFormUpdated
);

export const inquirySelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.inquiry
);

export const isClinicHostSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.isClinicHost
);

export const isClinicStepsFormExpandedSelector = createSelector(
    [selectAxaInsurance],
    (axaInsuranceState: IAxaInsuranceState) => axaInsuranceState.isClinicStepsFormExpanded
);
