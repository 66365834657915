import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterRegistrationAPI} from "./provider/clinicHunterRegistration";

export function resetPasswordAPI(
    email: string,
    resetPasswordComponentUrl: string
): Observable<any> {
    return clinicHunterRegistrationAPI.post(
        "reset-password",
        {
            email: email,
            resetPasswordComponentUrl: resetPasswordComponentUrl
        },
        new RestQueryParams()
    );
}
