import {injectable} from 'inversify';
import store from '../store';
import {InquiryFormStep} from '../components/InquiryForm/inquiryFormStep';
import {FormControlType, IFormConfig} from "common-web";
import {IQuoteTableItem} from "../components/InquiryForm/FormStepQuote/QuoteList";

export interface IFormConfigTranslationService {
    setTranslationKeys: (formConfig: typeof IFormConfig, stepName: InquiryFormStep) => typeof IFormConfig;
    setQuoteListTranslationKeys: (quoteTableConfig: IQuoteTableItem[], stepName: InquiryFormStep) => IQuoteTableItem[];
}

@injectable()
class FormConfigTranslationService implements IFormConfigTranslationService {

    public setTranslationKeys(formConfig: typeof IFormConfig, stepName: InquiryFormStep) {
        const globalState = store.getState(),
            clinicHost = globalState.axaInsurance.isClinicHost,
            translatedFormConfig = Object.assign({}, formConfig);
        translatedFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                if (control.hasOwnProperty("groupLabel")) {
                    let firstKey = Object.keys(control.controls)[0];
                        control.groupLabel = `form.${clinicHost ? 'clinicAxaForm' : 'axaForm'}.${stepName}.formControls.${firstKey}.groupLabel`;
                }

                Object.keys(control.controls).map((key: string) => {
                    const currentControl = control.controls[key];
                    if (currentControl.type === FormControlType.CHECKBOX) {
                        if(currentControl.isCheckboxLabelRaw) {
                            return currentControl;
                        }
                        return currentControl.checkboxLabel = `form.${clinicHost ? 'clinicAxaForm' : 'axaForm'}.${stepName}.formControls.${key}.checkboxLabel`
                    }
                    currentControl.label = `form.${clinicHost ? 'clinicAxaForm' : 'axaForm'}.${stepName}.formControls.${key}.label`;
                    currentControl.placeholder = `form.${clinicHost ? 'clinicAxaForm' : 'axaForm'}.${stepName}.formControls.${key}.placeholder`;
                    return currentControl;
                });
            }
            return control;
        });
        return translatedFormConfig;
    }

    public setQuoteListTranslationKeys(quoteTableConfig: IQuoteTableItem[], stepName: InquiryFormStep) {
        const globalState = store.getState(),
            clinicHost = globalState.axaInsurance.isClinicHost;
        const quoteTableConfigTranslated = quoteTableConfig.map((control: any) => {
            const currentControlLabelTranslated = `form.${clinicHost ? 'clinicAxaForm' : 'axaForm'}.${stepName}.${control.name}.label`;
            control.label = currentControlLabelTranslated;
            return control;
        })
        return quoteTableConfigTranslated;
    }
}

export default FormConfigTranslationService;
