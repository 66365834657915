import {InquiryFormStep} from '../InquiryForm/inquiryFormStep';

export const stepsNotAuthenticated = [
    InquiryFormStep.TREATMENT,
    InquiryFormStep.PATIENT,
    InquiryFormStep.QUOTE,
    InquiryFormStep.ACCOUNT,
    InquiryFormStep.PAYMENT,
    InquiryFormStep.SUCCESS,
];

export const stepsAuthenticated = [
    InquiryFormStep.TREATMENT,
    InquiryFormStep.PATIENT,
    InquiryFormStep.QUOTE,
    InquiryFormStep.PAYMENT,
    InquiryFormStep.SUCCESS,
];
