import {InquiryFormStep} from '../InquiryForm/inquiryFormStep';

export const stepsClinic = [
    InquiryFormStep.TREATMENT,
    InquiryFormStep.PATIENT,
    InquiryFormStep.QUOTE,
];

export const stepsInsuranceForPatient = [
    InquiryFormStep.TREATMENT,
    InquiryFormStep.PATIENT,
    InquiryFormStep.QUOTE,
    InquiryFormStep.PATIENT_EMAIL,
    InquiryFormStep.PAYMENT,
    InquiryFormStep.SUCCESS,
]
