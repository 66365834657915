import {injectable} from 'inversify';
import {changeInquiryForm, ICompanionItem, IInquiryForm} from '../store/reducers/axaInsuranceSlice';
import {IInquirySubject} from "../model/iInquirySubject";
import store from "../store";
import {formatServerDateToString} from "common-web";

export interface IInquiryFormUpdateService {
    updateInquiryFormFromAPI(apiData: any): any;
}

@injectable()
export class InquiryFormUpdateService implements IInquiryFormUpdateService {

    public updateInquiryFormFromAPI(apiData: any): any {
        const mainInquirySubject: IInquirySubject = apiData.inquirySubjects.find((subject: IInquirySubject) => subject.main === true),
            companionsFromServer: IInquirySubject[] = apiData.inquirySubjects.filter((subject: IInquirySubject) => subject.main === false),
            patientCompanions: ICompanionItem[] = companionsFromServer.map((companion: IInquirySubject) => {
                let updatedCompanion: ICompanionItem;
                updatedCompanion = {
                    name: companion.firstName,
                    lastName: companion.lastName,
                    residency: companion.address?.country ? companion.address.country : ' ',
                    birthDate: formatServerDateToString(companion.birthDate)
                };
                return updatedCompanion;
            }),
            quote = apiData.offers[0].fee;

        const companion = patientCompanions && Array.isArray(patientCompanions) && patientCompanions.length ?
            patientCompanions[0] : null;

        const updatedGlobalStateInquiry: IInquiryForm = {
            destinationId: apiData.destination,
            treatmentCategoryId: apiData.treatmentCategory.id,
            departure: formatServerDateToString(apiData.from),
            return: formatServerDateToString(apiData.to),
            cid: apiData.clinic?.id,
            residency: mainInquirySubject.address?.country ? mainInquirySubject.address.country : null,
            name: mainInquirySubject.firstName,
            lastName: mainInquirySubject.lastName,
            birthDate: formatServerDateToString(mainInquirySubject.birthDate),
            street: mainInquirySubject.address?.addressLine1 ? mainInquirySubject.address.addressLine1 : null,
            city: mainInquirySubject.address?.city ? mainInquirySubject.address.city : null,
            postCode: mainInquirySubject.address?.postcode ? mainInquirySubject.address.postcode : null,
            agreeToConditions: null,
            cardholderName: null,
            loginEmail: null,
            loginPassword: null,
            registrationEmail: null,
            registrationPassword: null,
            registrationPasswordConfirm: null,
            registrationTermsAgreement: null,
            specialTermsAndConditions: null,
            needsAndRequirements: null,
            distanceContracts: null,
            addCompanion: apiData.inquirySubjects.length > 1,
            companionName: companion ? companion.name : null,
            companionLastName: companion ? companion.lastName : null,
            companionBirthDate: companion ? companion.birthDate : null,
            companionResidency: companion ? companion.residency : null,
            quote: quote,
            clinicName: apiData.clinicName ? apiData.clinicName : null,
            patientEmail: apiData.patientEmail ? apiData.patientEmail : null,
            visitDate: apiData.visitDate ? formatServerDateToString(apiData.visitDate) : null,
            source: apiData.source ? apiData.source : null,
        };

        store.dispatch(changeInquiryForm(updatedGlobalStateInquiry));
    };
}

export default InquiryFormUpdateService;

