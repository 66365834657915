import {
  FormControlChangeType,
  FormControlType,
  IFormConfig,
  InputType,
  MultiSelectType,
  residencyCountryListLabels,
  ValidationRules
} from 'common-web';
import moment from "moment";
import store from '../../../store';
import {inquiryFormSelector} from '../../../store/selectors/axaInsuranceSelectors';
import i18next from 'i18next';

export const patientFormConfig: typeof IFormConfig = {
    controlType: 'group',
    class: '',
    dataAccessor: (data: any) => data,
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
      Object.assign(previousStateSnapshot, data);

      return previousStateSnapshot;
    },
    controls: [
      {
        controlType: 'group',
        key: 'residency_birthdate',
        class: 'form-row',
        controls: {
          residency: {
            controlType: 'control',
            defaultValue: '',
            hostClass: 'col-50',
            placeholder: 'form.axaForm.patient.formControls.residency.placeholder',
            formControlType: FormControlType.AUTOCOMPLETE,
            multiselectType: MultiSelectType.SINGLE,
            label: "form.axaForm.patient.formControls.residency.label",
            validationRules: [
              {name: ValidationRules.IS_REQUIRED},
            ],
            multiselectOptions: () => {
              const form = inquiryFormSelector(store.getState()),
                  destinationId = form.destinationId;

              return residencyCountryListLabels.map((country: any) => ({
                value: country.value,
                label: i18next.t(`residencyCountries.${country.label}`),
                optionColor: country.value === 'GB' ? '#ccc' : undefined,
                isDisabled: country.value === destinationId,
              }))
            },
            inputDataMapper: (data: any) => {
              if (!data) {
                return [];
              }

              return residencyCountryListLabels
                  .filter((country: any) => country.value === data)
                  .map((country: any) => ({
                    value: country.value,
                    label: i18next.t(`residencyCountries.${country.label}`),
                    optionColor: country.value === 'GB' ? '#ccc' : undefined,
                  }));
            },
            outputDataMapper: (data: any) => {
              if (Array.isArray(data)) {
                return (data.length > 0) ? data[0].value : null;
              }
              if (data && typeof data === 'object') {
                return data.value;
              }

              return data;
            },
          },
          birthDate: {
            controlType: 'control',
            defaultValue: '',
            hostClass: 'col-50',
            formControlType: FormControlType.DATE,
            validationRules: [
              {name: ValidationRules.IS_REQUIRED},
            ],
            placeholder: 'form.axaForm.patient.formControls.birthDate.placeholder',
            label: "form.axaForm.patient.formControls.birthDate.label",
            maxDate: moment().format('YYYY-MM-DD'),
            openToDate: moment('01/01/2000').format('YYYY-MM-DD'),
            type: InputType.DATE,
            showMonthDropdown: true,
            showYearDropdown: true,
          }
        }
      },
      {
        controlType: 'group',
        key: 'name',
        class: 'form-row',
        controls: {
          name: {
            controlType: 'control',
            defaultValue: '',
            hostClass: 'col-50',
            formControlType: FormControlType.INPUT,
            validationRules: [
              {name: ValidationRules.IS_REQUIRED},
              {name: ValidationRules.MIN_LENGTH, params: {length: 3}},
            ],
            placeholder: 'form.axaForm.patient.formControls.name.placeholder',
            label: "form.axaForm.patient.formControls.name.label",
            type: InputType.TEXT,
          },
          lastName: {
            controlType: 'control',
            defaultValue: '',
            hostClass: 'col-50',
            formControlType: FormControlType.INPUT,
            validationRules: [
              {name: ValidationRules.IS_REQUIRED},
              {name: ValidationRules.MIN_LENGTH, params: {length: 3}},
            ],
            placeholder: 'form.axaForm.patient.formControls.lastName.placeholder',
            label: "form.axaForm.patient.formControls.lastName.label",
            type: InputType.TEXT,
          }
        }
      },
      {
        controlType: 'group',
        key: 'city_postcode',
        class: 'form-row mb-0',
        controls: {
          city: {
            controlType: 'control',
            defaultValue: '',
            hostClass: 'col-50',
            formControlType: FormControlType.INPUT,
            validationRules: [
              {name: ValidationRules.IS_REQUIRED},
            ],
            placeholder: 'form.axaForm.patient.formControls.city.placeholder',
            label: "form.axaForm.patient.formControls.city.label",
            isLabelHidden: false,
            type: InputType.TEXT,
          },
          postCode: {
            controlType: 'control',
            defaultValue: '',
            hostClass: 'col-50',
            formControlType: FormControlType.INPUT,
            validationRules: [
              {name: ValidationRules.IS_REQUIRED},
            ],
            placeholder: 'form.axaForm.patient.formControls.postCode.placeholder',
            label: "form.axaForm.patient.formControls.postCode.label",
            isLabelHidden: false,
            type: InputType.TEXT,
          }
        },
      },
      {
        controlType: 'group',
        key: 'street',
        class: 'form-row',
        controls: {
          street: {
            hostClass: 'col-100',
            controlType: 'control',
            defaultValue: '',
            additionalStyles: 'vertical',
            formControlType: FormControlType.INPUT,
            validationRules: [
              {name: ValidationRules.IS_REQUIRED},
            ],
            placeholder: 'form.axaForm.patient.formControls.street.placeholder',
            label: "form.axaForm.patient.formControls.street.label",
            isLabelHidden: true,
            type: InputType.TEXT,
          }
        }
      },
      {
        controlType: 'group',
        key: 'add_companion',
        class: 'form-row',
        controls: {
          addCompanion: {
            hostClass: 'col-100 d-block',
            controlType: 'control',
            defaultValue: null,
            formControlType: FormControlType.RADIO,
            options: [
              {value: true, displayValue: 'yes'},
              {value: false, displayValue: 'no'}
            ],
            validationRules: [
              {name: ValidationRules.IS_REQUIRED},
            ],
            placeholder: 'form.axaForm.patient.formControls.addCompanion.placeholder',
            label: "form.axaForm.patient.formControls.addCompanion.label",
            type: InputType.RADIO,
            outputDataMapper: (data: any, previous: any, controlName: string, changeType: typeof FormControlChangeType) => {
              if (changeType === FormControlChangeType.Internal && data === false && previous === null) {
                return null;
              }
              if (data === 'false') {
                return false;
              }
              if (data === 'true' || data === 'on') {
                return true;
              }

              return data;
            },
            isOptionTranslated: true,
          }
        }
      }
    ]
};
