import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";
import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {IInquirySubject} from "../model/iInquirySubject";

export function createInquiryAPI(
    credentials: {
        authToken: string | undefined,
        secret: string | undefined,
    },
    from: Date,
    to: Date,
    treatmentCategoryId: string,
    destinationId: string,
    inquirySubjects: IInquirySubject[],
    clinicId: string | null,
    clinicName: string | null,
    patientEmail: string | null,
    visitDate: string | null,
    source: string | null,
    lifeThreat: boolean = true,
    reference: null = null, // why? type is null???
): Observable<any> {
    const payload: any = {
            lifeThreat: lifeThreat,
            reference: reference,
            inquirySubjects: inquirySubjects,
            clinicId: clinicId,
            from: from,
            to: to,
            treatmentCategoryId: treatmentCategoryId,
            destination: destinationId,
            clinicName: clinicName,
            patientEmail: patientEmail,
            visitDate: visitDate,
            source: source,
        };
    let headers = undefined;
    if (undefined !== credentials.authToken) {
        headers = {
            Authorization: `Bearer ${credentials.authToken}`,
        };
    } else {
        payload.secret = credentials.secret;
    }

    if (credentials.authToken && credentials.secret) {
        payload.secret = credentials.secret;
    }

    return clinicHunterInquiryAPI.post(
        "inquiries",
        payload,
        new RestQueryParams(),
        headers
    );
}
