export const countryList = [
    {label: 'Albania', value: 'AL'},
    {label: 'Andorra', value: 'AD'},
    {label: 'Austria', value: 'AT'},
    {label: 'Belarus', value: 'BY'},
    {label: 'Belgium', value: 'BE'},
    {label: 'Bosnia and Herzegovina', value: 'BA'},
    {label: 'Bulgaria', value: 'BG'},
    {label: 'Croatia', value: 'HR'},
    {label: 'Republic of Cyprus', value: 'CY'},
    {label: 'Czech Republic', value: 'CZ'},
    {label: 'Denmark', value: 'DK'},
    {label: 'Estonia', value: 'EE'},
    {label: 'Finland', value: 'FI'},
    {label: 'France', value: 'FR'},
    {label: 'Germany', value: 'DE'},
    {label: 'Greece', value: 'GR'},
    {label: 'Hungary', value: 'HU'},
    {label: 'Ireland', value: 'IE'},
    {label: 'Iceland', value: 'IS'},
    {label: 'Italy', value: 'IT'},
    {label: 'Kosovo', value: 'XK'},
    {label: 'Latvia', value: 'LV'},
    {label: 'Liechtenstein', value: 'LI'},
    {label: 'Lithuania', value: 'LT'},
    {label: 'Luxembourg', value: 'LU'},
    {label: 'Malta', value: 'MT'},
    {label: 'Moldova', value: 'MD'},
    {label: 'Monaco', value: 'MC'},
    {label: 'Montenegro', value: 'ME'},
    {label: 'Netherlands', value: 'NL'},
    {label: 'North Macedonia', value: 'MK'},
    {label: 'Norway', value: 'NO'},
    {label: 'Poland', value: 'PL'},
    {label: 'Portugal', value: 'PT'},
    {label: 'Romania', value: 'RO'},
    {label: 'Russian Federation', value: 'RU'},
    {label: 'San Marino', value: 'SM'},
    {label: 'Serbia', value: 'RS'},
    {label: 'Slovakia', value: 'SK'},
    {label: 'Slovenia', value: 'SI'},
    {label: 'Switzerland', value: 'CH'},
    {label: 'Spain', value: 'ES'},
    {label: 'Sweden', value: 'SE'},
    {label: 'Turkey', value: 'TR'},
    {label: 'Ukraine', value: 'UA'},
    {label: 'United Kingdom', value: 'GB'},
    {label: 'Vatican City', value: 'VA'},
];
