import { createSlice } from "redux-starter-kit";

export interface ITreatmentCategory {
  labels: {
    en: string;
    pl: string;
  };
  value: string;
}

export interface IMetaDataState {
  treatmentCategories: ITreatmentCategory[];
  isLoading: boolean;
  error: string | null;
}

export interface IChangeTreatmentCategories {
  readonly payload: {
      treatmentCategories: ITreatmentCategory[];
  };
}
export interface IChangeMetadataLoading {
  readonly payload: {
    isLoading: boolean;
  };
}

export interface ISetMetadataError {
  readonly payload: {
    error: string | null;
  };
}

const initialState: IMetaDataState = {
    treatmentCategories: [],
    isLoading: false,
    error: null
};

const metadataSlice = createSlice({
  slice: "metadata",
  initialState: initialState,
  reducers: {
    changeTreatmentCategories: {
      reducer: (state: IMetaDataState, action: IChangeTreatmentCategories) => {
        return {
          treatmentCategories: action.payload.treatmentCategories,
          isLoading: state.isLoading,
          error: state.error
        }
      },
      prepare(treatmentCategories: ITreatmentCategory[]) {
        return {
           payload: { treatmentCategories: treatmentCategories }
        };
      }
    },
    changeMetadataLoading: {
      reducer: (state: IMetaDataState, action: IChangeMetadataLoading) => {
        return {
          treatmentCategories: state.treatmentCategories,
          isLoading: action.payload.isLoading,
          error: state.error
        }
      },
      prepare(isLoading: boolean) {
        return {
          payload: { isLoading: isLoading }
        };
      }
    },
    setMetadataError: {
      reducer: (state: IMetaDataState, action: ISetMetadataError) => {
        return {
          treatmentCategories: state.treatmentCategories,
          isLoading: state.isLoading,
          error: action.payload.error
        }
      },
      prepare(error: string) {
        return {
          payload: { error: error }
        };
      }
    }
  }
});

export const {
  changeTreatmentCategories,
  changeMetadataLoading,
  setMetadataError
} = metadataSlice.actions;
export default metadataSlice.reducer;
