import * as React from "react";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {currentStepSelector} from "../../../store/selectors/axaInsuranceSelectors";
import {RootState} from "../../../store/reducers";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {InquiryFormStep} from "../../InquiryForm/inquiryFormStep";

interface IClipArtImageProps extends RouteComponentProps {
    currentStep: InquiryFormStep;
}

class ClipArtImage extends React.Component<IClipArtImageProps> {
    render() {
        return (<div className={`${styles.clipArt} ${styles[this.props.currentStep]}`} />)
    }
}

export default connect(
    (state: RootState) => ({
        currentStep: currentStepSelector(state),
    }),
    {}
)(withRouter(ClipArtImage));
