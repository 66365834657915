import {combineEpics} from 'redux-observable';
import {sharedLoginEpic, renewAuthTokenEpic, reauthenticateEpic} from 'common-web';
import authEpic from './auth';
import initFormEpic from "./initForm";

export const rootEpic = combineEpics(
    initFormEpic,
    authEpic,
    sharedLoginEpic,
    renewAuthTokenEpic,
    reauthenticateEpic
);
