import * as React from "react";
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {renewAuthToken, Toast} from 'common-web';
import {changeFormSteps, changeIsClinicHost, changeIsClinicStepsFormExpanded} from '../../store/reducers/axaInsuranceSlice';
import {authTokenSelector, refreshTokenSelector} from "../../store/selectors/authSelectors";
import {setAuthTokens} from "store/reducers/authSlice";
import jwtDecode from 'jwt-decode';
import {Redirect, Route, Switch} from 'react-router';
import {InquiryFormStep} from '../InquiryForm/inquiryFormStep';
import ClinicInquiryFormHost from "./ClinicInquiryFormHost";
import {stepsClinic, stepsInsuranceForPatient} from './formSteps';
import {formStepsSelector} from '../../store/selectors/axaInsuranceSelectors';

interface IClinicAxaHostProps {
    readonly authToken: string;
    readonly formSteps: InquiryFormStep[];
    readonly refreshToken: string;
    readonly renewAuthToken: typeof renewAuthToken;
    readonly changeFormSteps: typeof changeFormSteps;
    readonly setAuthTokens: typeof setAuthTokens;
    readonly changeIsClinicHost: typeof changeIsClinicHost;
    readonly changeIsClinicStepsFormExpanded: typeof changeIsClinicStepsFormExpanded;
}

interface IClinicAxaHostState {
    refreshToken: string | null;
    count: number;
}

class ClinicAxaHost extends React.Component<IClinicAxaHostProps, IClinicAxaHostState> {
    private authInterval: any;
    private counterInterval: any;

    constructor(props: any) {
        super(props);

        this.state = {
            refreshToken: this.props.refreshToken,
            count: 0
        };
    }

    componentDidMount(): void {
        this.props.changeIsClinicHost(true);
        if ((this.props as any)?.location && (this.props as any)?.location.search) {
            const urlParams = new URLSearchParams((this.props as any)?.location.search),
                isPurchaseInquiry = (urlParams).get("purchaseInquiry"),
                authToken = (urlParams).get('token'),
                refreshToken = (urlParams).get('refreshToken');

            if (refreshToken && authToken) {
                this.setState({refreshToken});
                this.props.setAuthTokens(authToken, refreshToken);
            }

            if (isPurchaseInquiry === 'true') {
                this.props.changeIsClinicStepsFormExpanded(true);
                this.props.changeFormSteps(stepsInsuranceForPatient);
            }
        } else {
            this.props.changeIsClinicStepsFormExpanded(false);
            this.props.changeFormSteps(stepsClinic);
        }


        if (this.props.authToken) {
            this.checkTokenExpiration();
            this.authInterval = setInterval(this.checkTokenExpiration, 1000 * 60 * 5);
        }
    }

    componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any
    ): void {
        if (this.props.refreshToken !== prevProps.refreshToken) {
            this.setState({refreshToken: this.props.refreshToken})
        }

        if (this.props.authToken && (this.props.authToken !== prevProps.authToken)) {
            const decoded: any = jwtDecode(this.props.authToken),
                tokenExpiration = decoded.exp,
                count = tokenExpiration - Math.floor(new Date().getTime() / 1000);
            this.setState({count: count});
        }
    }


    componentWillUnmount() {
        clearInterval(this.authInterval);
        clearInterval(this.counterInterval);
    }

    private renewSession = () => {
        this.props.renewAuthToken(this.state.refreshToken, this.props.authToken);
    };

    private checkTokenExpiration = (): void => {

        if (this.props.authToken) {
            const decoded: any = jwtDecode(this.props.authToken),
                tokenExpiration = decoded.exp,
                count = tokenExpiration - Math.floor(new Date().getTime() / 1000);

            this.setState({count: count});

            if (count < 600) {
                this.renewSession();
            }
        }
    };

    render() {
        if (!this.props.formSteps.length) {
            return null;
        }

        return (
            <section className={`${styles.clinicAxaHost} clinic-axa-host`}>
                <Switch>
                    <Route path={`/c/inquiry/:step/:inquiryId`} component={ClinicInquiryFormHost}/>
                    <Route path={`/c/inquiry/:step`} component={ClinicInquiryFormHost}/>
                    <Route component={() => (<Redirect to={`/c/inquiry/${InquiryFormStep.TREATMENT}`}/>)}/>
                </Switch>
                <Toast/>
            </section>
        );
    }
}

export default connect(
    (state: any) => ({
        authToken: authTokenSelector(state),
        refreshToken: refreshTokenSelector(state),
        formSteps: formStepsSelector(state),
    }),
    {
        renewAuthToken,
        setAuthTokens,
        changeFormSteps,
        changeIsClinicHost,
        changeIsClinicStepsFormExpanded,
    }
)(ClinicAxaHost);
