import {FormControlType, IFormConfig, InputType, ValidationRules} from 'common-web';

export const patientEmailFormConfig: typeof IFormConfig = () => {
  return {
    controlType: 'form',
    class: '',
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
      Object.assign(previousStateSnapshot, data);

      return previousStateSnapshot;
    },
    controls: [
      {
        controlType: "group",
        key: "patient_emails",
        class: "form-row",
        validationRules: [
          {
            name: ValidationRules.ARE_FIELDS_SAME,
            params: {
              accessors: [(value: any) => value.patientEmail, (value: any) => value.patientConfirmEmail],
              errorMessage: ''
            }
          }
        ],
        controls: {
          patientEmail: {
            controlType: 'control',
            defaultValue: '',
            formControlType: FormControlType.INPUT,
            validationRules: [
              {name: ValidationRules.IS_REQUIRED, params: {}},
              {name: ValidationRules.IS_EMAIL, params: {}}
            ],
            placeholder: 'form.axaForm.patientEmail.formControls.email.placeholder',
            isLabelHidden: false,
            label: 'form.axaForm.patientEmail.formControls.email.placeholder',
            type: InputType.EMAIL,
            hostClass: 'col-100',
            onCopy: (e: any) => {
              e.preventDefault();
              return false;
            },
          },
          patientConfirmEmail: {
            controlType: "control",
            hostClass: 'col-100',
            defaultValue: "",
            formControlType: FormControlType.INPUT,
            validationRules: [
              {name: ValidationRules.IS_REQUIRED},
              {name: ValidationRules.IS_EMAIL}
            ],
            placeholder: "form.axaForm.patientEmail.formControls.confirmEmail.placeholder",
            label: "form.axaForm.patientEmail.formControls.confirmEmail.placeholder",
            type: InputType.EMAIL,
            onPaste: (e: any) => {
              e.preventDefault();
              return false;
            },
          }
        }
      }
    ]
  }
};
