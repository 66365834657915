import React from 'react';
import './App.scss';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import AxaHost from "./components/AxaHost";
import {Route, Switch} from "react-router";
import ClinicAxaHost from "./components/ClinicAxaHost";

interface IAppProps extends RouteComponentProps {
}

class App extends React.Component<IAppProps> {

    render() {
        return (
            <div className="App">
                <Switch>
                    <Route path={`/c/`} component={ClinicAxaHost}/>
                    <Route path={`/`} component={AxaHost}/>
                </Switch>
            </div>
        );
    }
}

export default withRouter(App);
