import * as React from "react";
import {
    Form,
    formatMomentDateToString, FormButtonType,
    FormControlChangeType,
    getUTCMomentFromString,
    IFormConfig,
    Translation
} from 'common-web';
import {connect} from "react-redux";
import {changeCurrentStep, changeInquiryForm, IInquiryForm} from '../../../store/reducers/axaInsuranceSlice';
import {treatmentFormConfig} from "./treatmentFormConfig";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {InquiryFormStep} from '../inquiryFormStep';
import {IStepManagerService} from '../../../service/stepManagerService';
import {RootState} from "../../../store/reducers";
import {inquiryFormSelector, isInquiryFormUpdatedSelector} from "../../../store/selectors/axaInsuranceSelectors";
import {treatmentCategoriesSelector} from "../../../store/selectors/metadataSelectors";
import {ITreatmentCategory} from '../../../store/reducers/metadataSlice';
import {BehaviorSubject, Subscription} from "rxjs";
import {debounceTime, filter, tap} from "rxjs/operators";
import {IFormValidatorService} from '../../../service/formValidatorService';
import {IFormConfigTranslationService} from "../../../service/formConfigTranslationService";
import {activeLanguageSelector} from "../../../store/selectors/sagaSelectors";
import {LanguageLocale} from "../../../constants/locales";

interface IFormStepTreatmentProps {
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly changeInquiryForm: typeof changeInquiryForm;
    readonly inquiryForm: IInquiryForm;
    readonly activeLanguage: LanguageLocale;
    readonly isInquiryFormUpdated: boolean;
    readonly treatmentCategories: ITreatmentCategory[];
}

interface IFormStepTreatmentState {
    formConfig: typeof IFormConfig;
    value: any;
    isFormValid: boolean;
}

class FormStepTreatment extends React.Component<IFormStepTreatmentProps, IFormStepTreatmentState> {
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('FormValidatorService') private formValidator: IFormValidatorService;
    @lazyInject('FormConfigTranslationService') private formConfigTranslator: IFormConfigTranslationService;

    readonly stepName: InquiryFormStep = InquiryFormStep.TREATMENT;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    private isProcessing: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            formConfig: treatmentFormConfig,
            value: null,
            isFormValid: true,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        const translatedFormConfig = this.formConfigTranslator.setTranslationKeys(this.state.formConfig, this.stepName);
        this.setState({formConfig: translatedFormConfig});
        this.props.changeCurrentStep(this.stepName);
        if (this.props.inquiryForm) {
            this.updateFormFromState();
        }

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap(() => this.isProcessing = true),
                debounceTime(500),
                tap((data: any) => this.onFormValueChange(data.value)),
                tap(() => this.isProcessing = false),
            ).subscribe()
        );

        this.setTreatmentCategories();
    }

    componentDidUpdate(prevProps: Readonly<IFormStepTreatmentProps>, prevState: Readonly<IFormStepTreatmentState>, snapshot?: any) {
        if (this.props.inquiryForm !== prevProps.inquiryForm) {
            this.updateFormFromState();
        }

        if (this.props.treatmentCategories !== prevProps.treatmentCategories || this.props.activeLanguage !== prevProps.activeLanguage) {
            this.setTreatmentCategories();
        }

        if (this.props.activeLanguage !== prevProps.activeLanguage) {
            this.props.changeCurrentStep(this.stepName);
            this.setTreatmentCategories();
        }
    }

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <header>
                    <h2 className={'sr-only'}><Translation text={`form.axaForm.treatment.title`}/></h2>
                </header>
                <Form config={this.state.formConfig}
                      controlName={'treatmentForm'}
                      onValueStateChange={this.onValueStateChange}
                      onValidationStateChange={this.onValidationStateChange}
                      onButtonClicked={this.goNext}
                      value={this.state.value}/>
            </React.Fragment>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private goNext = (name: string, type: typeof FormButtonType) => {
        if (type !== FormButtonType.SUBMIT || this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.stepManager.goNext(this.stepName)
    };

    // ========== CUSTOM METHODS ========== //

    private onFormValueChange = (value: any) => {
        const departureDate = value.departure ? getUTCMomentFromString(value.departure) : null;
        const returnDate = value.return ? getUTCMomentFromString(value.return) : null;
        if (null !== departureDate) {
            treatmentFormConfig.controls[3].controls.return.minDate = formatMomentDateToString(departureDate);
            treatmentFormConfig.controls[4].controls.visitDate.minDate = formatMomentDateToString(departureDate);
            if (null !== returnDate && departureDate > returnDate) {
                value.return = formatMomentDateToString(departureDate);
            }
            treatmentFormConfig.controls[3].controls.departure.openToDate = formatMomentDateToString(departureDate);
        }
        if (null !== returnDate) {
            treatmentFormConfig.controls[3].controls.return.openToDate = formatMomentDateToString(returnDate);
            treatmentFormConfig.controls[4].controls.visitDate.maxDate = formatMomentDateToString(returnDate);
        }
        this.props.changeInquiryForm(value);
    };

    private setTreatmentCategories = () => {
        if (!this.props.treatmentCategories || !this.props.treatmentCategories.length) {
            return;
        }

        treatmentFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'treatmentCategoryId') {
                        control.controls[key].multiselectOptions = this.props.treatmentCategories.map(category => {
                            const categoryLabel = category.labels.hasOwnProperty(this.props.activeLanguage) ? category.labels[this.props.activeLanguage] :
                                category.labels[LanguageLocale.EN];

                            return {
                                value: category.value,
                                label: categoryLabel
                            }
                        });
                    }
                });
            }

            return control;
        });

        this.setState({formConfig: treatmentFormConfig});
    };

    private updateFormFromState = () => {
        const inquiry = this.props.inquiryForm;
        this.setState({
            isFormValid: this.formValidator.isStepValid(this.stepName),
            value: {
                destinationId: inquiry.destinationId,
                treatmentCategoryId: inquiry.treatmentCategoryId,
                clinicName: inquiry.clinicName ? inquiry.clinicName : null,
                departure: inquiry.departure,
                return: inquiry.return,
                visitDate: inquiry.visitDate,
            }
        })
    }
}

export default connect(
    (state: RootState) => ({
        inquiryForm: inquiryFormSelector(state),
        isInquiryFormUpdated: isInquiryFormUpdatedSelector(state),
        treatmentCategories: treatmentCategoriesSelector(state),
        activeLanguage: activeLanguageSelector(state),
    }),
    {
        changeCurrentStep,
        changeInquiryForm
    }
)(FormStepTreatment);
