import { Observable } from "rxjs";
import { RestQueryParams } from "./base/queryParams";
import { clinicHunterRegistrationAPI } from "./provider/clinicHunterRegistration";

export function confirmResetPasswordAPI(
  plainPassword: string,
  resetPasswordToken: string
): Observable<any> {
  return clinicHunterRegistrationAPI.post(
    `reset-password`,
    {
      plainPassword: plainPassword,
    },
    new RestQueryParams(),
      {
          'X-Authentication-Token': resetPasswordToken
      }
  );
}
