import * as React from "react";
import {Translation} from "common-web";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {isClinicHostSelector, inquiryIdSelector,} from '../../../store/selectors/axaInsuranceSelectors';
import {changeCurrentStep,} from "../../../store/reducers/axaInsuranceSlice";
import {Redirect} from "react-router-dom";
import styles from "./styles.module.scss";
import {isAuthenticatedSelector} from "../../../store/selectors/authSelectors";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs"; // toDo wyjebać
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {InquiryFormStep} from '../inquiryFormStep';
import {IAlertManagerService} from "../../../service/alertManagerService";
import {IStepManagerService} from '../../../service/stepManagerService';
import RegisterComponent from "../../AuthComponents/RegisterComponent";
import LoginComponent from "../../AuthComponents/LoginComponent";
import {activeLanguageSelector} from "../../../store/selectors/sagaSelectors";
import {LanguageLocale} from "../../../constants/locales";
import {Trans} from "react-i18next";

interface IFormStepAccountProps {
    readonly isAuthenticated: boolean;
    readonly inquiryId: string | null;
    readonly isClinicHost: boolean;
    readonly activeLanguage: LanguageLocale;
    readonly changeCurrentStep: typeof changeCurrentStep;
}

interface IFormStepAccountState {
    accountCurrentTab: number;
}

class FormStepAccount extends React.Component<IFormStepAccountProps, IFormStepAccountState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;

    constructor(props: any) {
        super(props);
        this.state = {
            accountCurrentTab: 0,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.props.changeCurrentStep(InquiryFormStep.ACCOUNT);
    }

    render() {
        if (this.props.isAuthenticated) {
            const lng = !this.props.isClinicHost ? `/${this.props.activeLanguage}` : '';
            let path = `${this.props.isClinicHost ? '/c' : ''}${lng}/inquiry/${InquiryFormStep.PAYMENT}`;
            if (this.props.inquiryId) {
                path += `/${this.props.inquiryId}`;
            }

            return (<Redirect to={path}/>);
        }

        const isFirstTabChosen = this.state.accountCurrentTab === 0;
        return (
            <React.Fragment>
                <Tabs className={styles.tabs}>
                    <TabList className={styles.tabList}>
                        <header>
                            <h2 className={styles.accountHeader}>
                                <Translation text={`form.axaForm.account.${isFirstTabChosen ? 'registerHeading' : 'loginHeading'}`}/>
                            </h2>
                            <p className={styles.accountSubheader}>
                                <Translation text={`form.axaForm.account.subHeader`}/>
                            </p>
                            <p className={styles.accountSubheader}>
                                <Trans
                                    i18nKey={'form.axaForm.account.description'}
                                    components={[<span className={styles.description}></span>]}
                                />
                            </p>
                            <p className={styles.accountSubheader}>
                                <Tab className={styles.tab}
                                     selectedClassName={styles.selected}
                                     onClick={() => this.accountTabsHandleChange(0)}>
                                <span className={styles.multiTabItem}>
                                    <Translation text={`registerForm.title`}/>
                                </span>
                                </Tab>
                                <Tab className={styles.tab}
                                     selectedClassName={styles.selected}
                                     onClick={() => this.accountTabsHandleChange(1)}>
                                <span className={styles.multiTabItem}>
                                    <Translation text={`loginForm.info`}/>
                                </span>
                                </Tab>
                            </p>
                        </header>
                    </TabList>
                    <TabPanel className={styles.tabPanel} selectedClassName={styles.selected}>
                        <RegisterComponent/>
                    </TabPanel>
                    <TabPanel className={styles.tabPanel} selectedClassName={styles.selected}>
                        <LoginComponent/>
                    </TabPanel>
                </Tabs>
            </React.Fragment>
        )
    }

    private accountTabsHandleChange = (value: any) => {
        this.setState({
            accountCurrentTab: value
        });
    };
}

export default connect(
    (state: RootState) => ({
        isAuthenticated: isAuthenticatedSelector(state),
        isClinicHost: isClinicHostSelector(state),
        inquiryId: inquiryIdSelector(state),
        activeLanguage: activeLanguageSelector(state),
    }),
    {
        changeCurrentStep,
    }
)(FormStepAccount);

