import * as React from "react";
import {Translation} from 'common-web';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import styles from "./styles.module.scss";
import {usernameSelector} from "../../../store/selectors/authSelectors";
import {InquiryFormStep} from "../inquiryFormStep";
import {changeCurrentStep, resetFormAndInitialize, resetClinicFormAndInitialize} from "../../../store/reducers/axaInsuranceSlice";
import {isClinicHostSelector} from "../../../store/selectors/axaInsuranceSelectors";


interface IFormStepSuccessProps {
    readonly userName: string | null;
    readonly isClinicHost: boolean;
    readonly currentStep: InquiryFormStep;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly resetFormAndInitialize: typeof resetFormAndInitialize;
    readonly resetClinicFormAndInitialize: typeof resetClinicFormAndInitialize;
}

interface IFormStepSuccessState {
}

class FormStepSuccess extends React.Component<IFormStepSuccessProps, IFormStepSuccessState> {
    readonly stepName: InquiryFormStep = InquiryFormStep.SUCCESS;
    constructor(props: any) {
        super(props);
        this.state = {}
    }


    componentDidMount() {
        if (!this.props.isClinicHost) {
            this.props.changeCurrentStep(InquiryFormStep.SUCCESS);
        }
    }

    componentWillUnmount() {
        if (!this.props.isClinicHost) {
            this.props.resetFormAndInitialize();
        } else {
            this.props.resetClinicFormAndInitialize();
        }
    }

    render() {
        const successMessage = !this.props.isClinicHost ? `form.axaForm.successPage.subTitle` : 'form.clinicAxaForm.successPage.subTitle';
        return (
            <React.Fragment>
                <div className={styles.successPageWrapper}>
                    <div className={styles.completedIcon}>
                        <div className={styles.checked}/>
                    </div>
                    <div className={styles.successPageContent}>
                        <h2 className={styles.successTitle}>
                            <Translation text={`form.axaForm.successPage.title`}/>
                        </h2>
                        <p className={styles.successSubTitle}><Translation text={successMessage}/></p>
                        {!this.props.isClinicHost ?
                            (<div className={styles.successButtonWrapper}>
                                <button type="button" name="forward"
                                        className="btn btn-primary"
                                        onClick={() => this.goToClinicHunter()}>
                                    <Translation text={`form.buttons.backToClinic`}/>
                                </button>
                            </div>) : null
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

    goToClinicHunter = () => {
        window.location.href = 'https://clinichunter.com/';
    }
}

export default connect(
    (state: RootState) => ({
        userName: usernameSelector(state),
        isClinicHost: isClinicHostSelector(state),
    }),
    {
        changeCurrentStep,
        resetFormAndInitialize,
        resetClinicFormAndInitialize
    }
)(FormStepSuccess);
