import {FormControlType, IFormConfig, InputType, MultiSelectType, ValidationRules} from "common-web";
import i18next from 'i18next';
import React from 'react';

function getOriginSelectOptions() {
    return [
        {value: 'clinic', label: i18next.t('registerForm.formControls.originOptions.clinic')},
        {value: 'friend', label: i18next.t('registerForm.formControls.originOptions.friend')},
        {value: 'facebook', label: i18next.t('registerForm.formControls.originOptions.facebook')},
        {value: 'google', label: i18next.t('registerForm.formControls.originOptions.google')},
        {value: 'other', label: i18next.t('registerForm.formControls.originOptions.other')},
    ]
}

export const loginFormConfig: typeof IFormConfig = {
    controlType: "form",
    class: "",
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            key: "auth_login",
            controlType: "group",
            class: "form-row",
            controls: {
                loginEmail: {
                    controlType: "control",
                    hostClass: "col-100",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_EMAIL}
                    ],
                    placeholder: "loginForm.formControls.loginEmail.placeholder",
                    label: "loginForm.formControls.loginEmail.label",
                    type: InputType.EMAIL,
                }
            }
        },
        {
            key: "auth_password",
            controlType: "group",
            class: "form-row",
            controls: {
                loginPassword: {
                    controlType: "control",
                    hostClass: "col-100",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: "loginForm.formControls.loginPassword.placeholder",
                    label: "loginForm.formControls.loginPassword.label",
                    type: InputType.PASSWORD,
                }
            }
        }
    ]
};

export const registerFormConfig: typeof IFormConfig = {
    controlType: "form",
    class: "",
    dataAccessor: (data: any) => data, // data is not grouped
    outputDataMapper: (data: any, previousStateSnapshot: any) => {
        Object.assign(previousStateSnapshot, data);
        return previousStateSnapshot;
    },
    controls: [
        {
            controlType: "group",
            key: "registration_email",
            class: "form-row",
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {
                        accessors: [(value: any) => value.registrationEmail, (value: any) => value.registrationConfirmEmail],
                        errorMessage: "formValidation.errors.sameEmail"
                    }
                }
            ],
            controls: {
                registrationEmail: {
                    controlType: "control",
                    hostClass: "col-100",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_EMAIL}
                    ],
                    placeholder: "registerForm.formControls.registrationEmail.placeholder",
                    label: "registerForm.formControls.registrationEmail.label",
                    type: InputType.EMAIL,
                    onCopy: (e: any) => {
                        e.preventDefault();
                        return false;
                    },
                },
                registrationConfirmEmail: {
                    controlType: "control",
                    hostClass: "col-100",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.IS_EMAIL}
                    ],
                    placeholder: "registerForm.formControls.registrationConfirmEmail.placeholder",
                    label: "registerForm.formControls.registrationConfirmEmail.label",
                    type: InputType.EMAIL,
                    onPaste: (e: any) => {
                        e.preventDefault();
                        return false;
                    },
                }
            }
        },
        {
            key: "registration_password",
            controlType: "group",
            class: "form-row",
            validationRules: [
                {
                    name: ValidationRules.ARE_FIELDS_SAME,
                    params: {accessors: [(value: any) => value.registrationPassword, (value: any) => value.registrationPasswordConfirm]}
                }
            ],
            controls: {
                registrationPassword: {
                    controlType: "control",
                    hostClass: "col-50",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: "registerForm.formControls.registrationPassword.placeholder",
                    label: "registerForm.formControls.registrationPassword.label",
                    type: InputType.PASSWORD,
                },
                registrationPasswordConfirm: {
                    controlType: "control",
                    hostClass: "col-50",
                    defaultValue: "",
                    formControlType: FormControlType.INPUT,
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                        {name: ValidationRules.MIN_LENGTH, params: {length: 4}}
                    ],
                    placeholder: "registerForm.formControls.registrationPasswordConfirm.placeholder",
                    label: "registerForm.formControls.registrationPasswordConfirm.label",
                    type: InputType.PASSWORD,
                }
            }
        },
        {
            key: "clauses",
            controlType: "group",
            class: "form-row",
            controls: {
                registrationTermsAgreement: {
                    controlType: "control",
                    hostClass: "col-100",
                    defaultValue: false,
                    formControlType: FormControlType.CHECKBOX,
                    validationRules: [
                        {name: ValidationRules.IS_TRUE},
                    ],
                    isLabelHidden: true,
                    checkboxLabel: 'registerForm.formControls.registrationTermsAgreement.checkboxLabel',
                    isTranslationComponentCustom: true,
                    translationComponents: [<a href="https://clinichunter.com/privacy-policy/" target="_blank"></a>, <a href="https://clinichunter.com/personal-data-protection-policy/" target="_blank"></a>, <a href="https://clinichunter.com/personal-data-information-clause-art-13-gdpr/" target="_blank"></a>],
                    outputDataMapper: (data: any) => {
                        if (data === 'true' || data === 'on') {
                            return true;
                        }
                        if (data === 'false') {
                            return false;
                        }

                        return data;
                    },
                }
            }
        },
        {
            controlType: 'group',
            key: 'source',
            class: 'form-row',
            controls: {
                source: {
                    controlType: 'control',
                    defaultValue: '',
                    hostClass: '',
                    placeholder: 'form.axaForm.payment.formControls.source.placeholder',
                    formControlType: FormControlType.AUTOCOMPLETE,
                    multiselectType: MultiSelectType.SINGLE,
                    label: "form.axaForm.payment.formControls.source.label",
                    validationRules: [
                        {name: ValidationRules.IS_REQUIRED},
                    ],
                    multiselectOptions: () => {
                        const options = getOriginSelectOptions();
                        return options;
                    },
                    inputDataMapper: (data: any) => {
                        if (!data) {
                            return [];
                        }
                        const options = getOriginSelectOptions();
                        return options
                            .filter((option: any) => option.value === data)
                            .map((option: any) => ({
                                value: option.value,
                                label: i18next.t(`registerForm.formControls.originOptions.${option.label}`),
                            }));
                    },
                    outputDataMapper: (data: any) => {
                        if (Array.isArray(data)) {
                            return (data.length > 0) ? data[0].value : null;
                        }
                        if (data && typeof data === 'object') {
                            return data.value;
                        }

                        return data;
                    },
                }
            }
        },
    ]
};

export const customSourceControls = [
    {
        controlType: 'group',
        key: 'custom_source',
        class: 'form-row',
        controls: {
            customSource: {
                controlType: 'control',
                defaultValue: null,
                hostClass: '',
                formControlType: FormControlType.INPUT,
                validationRules: [
                    {name: ValidationRules.IS_REQUIRED},
                ],
                placeholder: 'form.axaForm.payment.formControls.customSource.placeholder',
                label: "form.axaForm.payment.formControls.customSource.label",
                type: InputType.TEXT,
            },
        }
    },
];
