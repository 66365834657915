const pl = {
    axaAdditionalInformation: {
        heading: "Formularz konfiguratora medycznego ubezpieczenia turystycznego",
        title: "Clinic Hunter Cover",
        subtitle: "medyczne ubezpieczenie turystyczne",
        howItWorks: "Jak to działa",
        aboutUs: "O nas",
        terms: "Warunki",
        claims: "Regulamin",
        faq: "FAQ",
    },
    form: {
        buttons: {
            next: "Następny krok",
            prev: "Poprzedni krok",
            buy: "Kup teraz",
            backToEdit: "Wróć do edycji",
            backToClinic: "Powrót do Clinic Hunter",
        },
        axaForm: {
            treatment: {
                title: "Leczenie",
                formControls: {
                    destinationId: {
                        label: "Dokąd jedziesz?",
                        placeholder: 'Wybierz kraj',
                    },
                    treatmentCategoryId: {
                        label: "Jakie leczenie podejmujesz?",
                        placeholder: 'Wybierz rodzaj leczenia'
                    },
                    clinicName: {
                        label: 'Jaka się nazywa klinika?',
                        placeholder: 'Wpisz nazwę kliniki',
                    },
                    departure: {
                        label: "Od",
                        groupLabel: "Data podróży",
                        placeholder: 'DD - MM - RRRR'
                    },
                    return: {
                        label: "do",
                        placeholder: 'DD - MM - RRRR'
                    },
                    visitDate: {
                        label: "Data zabiegu / Data pierwszej wizyty",
                        placeholder: "DD - MM - RRRR"
                    }
                }
            },
            patient: {
                title: "Pacjent",
                subtitle: "Formularz danych pacjenta",
                formControls: {
                    residency: {
                        label: "Twoje miejsce zamieszkania",
                        placeholder: 'Wybierz kraj',
                    },
                    birthDate: {
                        label: "Jaka jest Twoja data urodzenia?",
                        placeholder: 'DD - MM - RRRR'
                    },
                    name: {
                        label: "Jak masz na imię?",
                        placeholder: 'Imię'
                    },
                    lastName: {
                        label: "Jak masz na nazwisko?",
                        placeholder: 'Nazwisko'
                    },
                    city: {
                        label: "Jaki jest Twój adres?",
                        placeholder: 'Miasto'
                    },
                    postCode: {
                        label: "",
                        placeholder: 'Kod pocztowy'
                    },
                    street: {
                        label: "",
                        placeholder: 'Ulica, numer mieszkania'
                    },
                    email: {
                        label: "Adres e-mail",
                        placeholder: "Adres e-mail"
                    },
                    addCompanion: {
                        label: "Czy chcesz by osoby towarzyszące były ubezpieczone?",
                        placeholder: ''
                    },
                    companionResidency: {
                        label: `Jakie jest miejsce zamieszkania osoby towarzyszącej?`,
                        placeholder: 'Kraj'
                    },
                    companionBirthDate: {
                        label: `Jaka jest data urodzenia osoby towarzyszącej`,
                        placeholder: 'DD - MM - RRRR'
                    },
                    companionName: {
                        label: `Jak ma na imię osoba towarzysząca?`,
                        placeholder: 'Imię'
                    },
                    companionLastName: {
                        label: `Nazwisko osoby towarzyszącej?`,
                        placeholder: 'Nazwisko'
                    }
                }
            },
            patientEmail: {
                title: "Adres e-mail pacjenta",
                subtitle: "Prosimy o podanie adresu e-mail pacjenta w celu przesłania szczegółów ubezpieczenia",
                formControls: {
                    patientEmail: {
                        label: "Jaki jest adres e-mail pacjenta?",
                        placeholder: 'E-mail',
                    },
                    patientConfirmEmail: {
                        label: "Potwierdź adres e-mail pacjenta",
                        placeholder: "E-mail",
                    }
                }
            },
            quote: {
                title: "Wycena",
                name: {
                    label: 'Imię i nazwisko pacjenta'
                },
                address: {
                    label: 'Adres'
                },
                birthDate: {
                    label: 'Data urodzenia'
                },
                destination: {
                    label: 'Miejsce docelowe'
                },
                treatment: {
                    label: 'Leczenie'
                },
                departure: {
                    label: 'Od'
                },
                return: {
                    label: 'Do'
                },
                quote: {
                    label: 'Cena'
                },
                discountQuote: {
                    label: 'Zniżka 10%'
                },
                companion: {
                    label: `Osoba towarzysząca`
                }
            },
            account: {
                title: "Konto",
                registerHeading: "Stwórz konto",
                loginHeading: "Zaloguj się",
                subHeader: "Gratulacje! Proces konfiguracji Ubezpieczenia jest już prawie zakończony. Podaj nam swój adres e-mail, aby założyć konto.",
                description: `<0>Czemu potrzebuję konta?</0> Na swoim koncie będziesz miał dostęp do szczegółów polisy i będziesz mógł wprowadzać zmiany do swoich danych.`,
            },
            payment: {
                title: "Płatność",
                formControls: {
                    cardholderName: {
                        label: "Imię i nazwisko widoczne na karcie:",
                        placeholder: 'Imię i nazwisko'
                    },
                    stripecard: {
                        label: 'Podaj szczegóły karty',
                        placeholder: ''
                    },
                    specialTermsAndConditions: {
                        checkboxLabel: `Przed zawarciem umowy ubezpieczenia otrzymałem/łam <0>Szczególne warunki ubezpieczenia turystycznego dla klientów ClinicHunter</0> oraz <1>Broszurę informacyjną dotyczącą przetwarzania danych osobowych</1> i zobowiązuję się do zapoznania wszystkich ubezpieczonych z ich treścią.`
                    },
                    needsAndRequirements: {
                        checkboxLabel: `Niniejsza umowa ubezpieczenia jest zgodna z moimi potrzebami i wymaganiami ubezpieczeniowymi.`
                    },
                    distanceContracts: {
                        checkboxLabel: `Przed zawarciem umowy ubezpieczenia zapoznałem/łam się <0>Regulaminem zawierania umów na odległość</0>.`
                    },
                    source: {
                        label: 'Jak się o nas dowiedziałeś/łaś?',
                        placeholder: 'Wybierz',
                    },
                    customSource: {
                        label: 'Wpisz inną opcję',
                        placeholder: 'Wpisz opcję',
                    }
                }
            },
            successPage: {
                title: "Dziękujemy!",
                subTitle: "Twoja wpłata czeka na potwierdzenie. Po zakończeniu procesu otrzymasz e-mail z ubezpieczeniem."
            }
        },
        clinicAxaForm: {
            treatment: {
                title: "Leczenie",
                formControls: {
                    destinationId: {
                        label: "Jakie jest miejsce docelowe Twojego pacjenta?",
                        placeholder: 'Wybierz kraj',
                    },
                    treatmentCategoryId: {
                        label: "Jakiego leczenia podejmuje się Twój Pacjent?",
                        placeholder: 'Wybierz leczenie'
                    },
                    clinicName: {
                        label: 'Jaka jest nazwa kliniki?',
                        placeholder: 'Wpisz nazwę kliniki',
                    },
                    departure: {
                        label: "Od",
                        groupLabel: "YData podróży pacjenta",
                        placeholder: 'DD - MM - RRRR'
                    },
                    return: {
                        label: "Do",
                        placeholder: 'DD - MM - RRRR'
                    },
                    visitDate: {
                        label: "Data zabiegu / Data pierwszej wizyty",
                        placeholder: "DD - MM - RRRR"
                    }
                }
            },
            patient: {
                title: "Pacjent",
                subtitle: "Formularz danych pacjenta",
                formControls: {
                    residency: {
                        label: "Jakie jest miejsce zamieszkania Twojego pacjenta?",
                        placeholder: 'Wybierz kraj',
                    },
                    birthDate: {
                        label: "Jaka jest data urodzenia Twojego pacjenta?",
                        placeholder: 'DD - MM - RRRR'
                    },
                    name: {
                        label: "Jakie imię ma Twój pacjent?",
                        placeholder: 'Imię'
                    },
                    lastName: {
                        label: "Jakie nazwisko ma Twój pacjent?",
                        placeholder: 'Nazwisko'
                    },
                    city: {
                        label: "Jaki jest adres Twojego pacjenta?",
                        placeholder: 'Miasto'
                    },
                    postCode: {
                        label: "",
                        placeholder: 'Kod pocztowy'
                    },
                    street: {
                        label: "",
                        placeholder: 'Ulica, numer mieszkania'
                    },
                    addCompanion: {
                        label: "Czy Twój Pacjent potrzebuje aby osoby towarzyszące były ubezpieczone?",
                        placeholder: ''
                    },
                    companionResidency: {
                        label: `Jakie jest miejsce zamieszkania osoby towarzyszącej Twojego Pacjenta?`,
                        placeholder: 'Kraj'
                    },
                    companionBirthDate: {
                        label: `Jaka jest data urodzenia osoby towarzyszącej Twojego pacjenta?`,
                        placeholder: 'DD - MM - RRRR'
                    },
                    companionName: {
                        label: `Jak ma na imię osoba towarzysząca Twojego pacjenta?`,
                        placeholder: 'Imię'
                    },
                    companionLastName: {
                        label: `Nazwisko osoby towarzyszącej Twojego pacjenta?`,
                        placeholder: 'Nazwisko'
                    }
                }
            },
            patientEmail: {
                title: "Adres e-mail Pacjenta",
                subtitle: "Prosimy o podanie adresu e-mail pacjenta w celu przesłania szczegółów ubezpieczenia",
                formControls: {
                    patientEmail: {
                        label: "Jaki jest adres e-mail Twojego Pacjenta?",
                        placeholder: 'E-mail',
                    },
                    patientConfirmEmail: {
                        label: "Potwierdź adres e-mail Twojego pacjenta",
                        placeholder: "E-mail",
                    }
                }
            },
            quote: {
                title: "Wycena",
                name: {
                    label: 'Imię i nazwisko Pacjenta'
                },
                address: {
                    label: 'Adres'
                },
                birthDate: {
                    label: 'Data urodzenia'
                },
                destination: {
                    label: 'Miejsce docelowe'
                },
                treatment: {
                    label: 'Leczenie'
                },
                departure: {
                    label: 'Od'
                },
                return: {
                    label: 'Do'
                },
                quote: {
                    label: 'Cena'
                },
                discountQuote: {
                    label: 'Zniżka 10%'
                },
                companion: {
                    label: `Osoba towarzysząca`
                }
            },
            account: {
                title: "Konto",
                registerHeading: "Stwórz konto",
                loginHeading: "Zaloguj się",
                subHeader: "Gratulacje! Proces konfiguracji Ubezpieczenia jest już prawie zakończony. Podaj nam swój adres e-mail, aby założyć konto.",
                description: `<0>Czemu potrzebuję konta?</0> Na swoim koncie będziesz miał dostęp do szczegółów polisy i będziesz mógł wprowadzać zmiany do swoich danych.`,
            },
            payment: {
                title: "Płatność",
                formControls: {
                    cardholderName: {
                        label: "Imię i nazwisko widoczne na karcie:",
                        placeholder: 'Imię i nazwisko'
                    },
                    stripecard: {
                        label: 'Podaj szczegóły karty',
                        placeholder: ''
                    },
                    specialTermsAndConditions: {
                        checkboxLabel: `Przed zawarciem umowy ubezpieczenia otrzymałem/łam <0>Szczególne warunki ubezpieczenia turystycznego dla klientów ClinicHunter</0> oraz <1>Broszurę informacyjną dotyczącą przetwarzania danych osobowych</1> i zobowiązuję się do zapoznania wszystkich ubezpieczonych z ich treścią.`
                    },
                    needsAndRequirements: {
                        checkboxLabel: `Niniejsza umowa ubezpieczenia jest zgodna z moimi potrzebami i wymaganiami ubezpieczeniowymi.`
                    },
                    distanceContracts: {
                        checkboxLabel: `Przed zawarciem umowy ubezpieczenia zapoznałem/łam się <0>Regulaminem zawierania umów na odległość</0>.`
                    },
                    source: {
                        label: 'Jak się o nas dowiedziałeś/łaś?',
                        placeholder: 'Wybierz',
                    },
                    customSource: {
                        label: 'Wpisz inną opcję',
                        placeholder: 'Wpisz opcję',
                    }
                }
            },
            successPage: {
                title: "Dziękujemy!",
                subTitle: "Twoja wpłata czeka na potwierdzenie. Po zakończeniu procesu otrzymasz e-mail z ubezpieczeniem."
            }
        }
    },
    formValidation: {
        errors: {
            minLength: 'Treść jest zbyt krótka',
            isEmailValid: 'Podaj poprawny adres e-mail',
            isRequired: 'Pole jest wymagane',
            isCheckedTrue: 'Pole musi być zaznaczone',
            isTrue: 'Pole musi być zaznaczone',
            isPasswordSame: 'Wpisane hasła nie są takie same',
            isStartDateValid: 'Data musi być po dniu bieżącym',
            isStartDateGreaterOrEvenValid: 'Data musi być po dniu bieżącym',
            isEndDateValid: 'Data powrotu musi nastąpić po wyjeździe',
            sameEmail: 'E-maile powinny być takie same',
            samePassword: 'Hasła powinny być takie same'
        }
    },
    loginForm: {
        title: "Nazwa użytkownika",
        info: 'Mam już konto',
        userName: "Nazwa użytkownika lub adres e-mail",
        password: "Hasło",
        buttons: {
            login: "Zaloguj się",
            logout: "Wyloguj się",
            dashboard: "Panel Pacjenta",
            profile: "Profil",
            messages: "Wiadomości",
            menuButtonNotLogged: "Zaloguj się",
            menuButtonLogged: "Menu"
        },
        formControls: {
            loginEmail: {
                label: "Adres e-mail",
                placeholder: "E-mail",
            },
            loginPassword: {
                label: "Hasło",
                placeholder: "Hasło",
            },
        }
    },
    registerUserForm: {
        title: "Rejestracja"
    },
    registerForm: {
        title: 'Chcę stworzyć konto',
        buttons: {
            register: 'Zarejestruj się'
        },
        formControls: {
            registrationEmail: {
                placeholder: "E-mail",
                label: "Adres e-mail",
            },
            registrationConfirmEmail: {
                placeholder: 'E-mail',
                label: 'Potwierdź adres e-mail'
            },
            registrationPassword: {
                placeholder: "Hasło",
                label: "Hasło",
            },
            registrationPasswordConfirm: {
                placeholder: "Powtórz hasło",
                label: "Potwierdź hasło",
            },
            registrationTermsAgreement: {
                checkboxLabel: `Wyrażam zgodę na <0>Politykę Prywatności</0>, <1>wykorzystanie danych osobowych</1> oraz <2>art. 13 RODO</2>*`,
            },
            originOptions: {
                clinic: 'Moja klinika',
                friend: 'Przyjaciel',
                facebook: 'Grupa na Facebooku',
                google: 'Wyszukiwarka Google',
                other: 'Inne'
            }
        }
    },
    companionsForm: {
        removeButtonFirst: `usuń `,
        removeButtonSecond: ` osobę towarzyszącą`,
        addButton: 'dodaj kolejną osobę towarzyszącą'
    },
    radioOptions: {
        yes: 'Tak',
        no: 'Nie',
    },
    alerts: {
        baseError: 'Coś poszło nie tak. Spróbuj ponownie później',
        unauthorized: 'Aby zobaczyć szczegóły ubezpieczenia, musisz się zalogować.',
        inquiryAlreadyPurchased: 'Ubezpieczenie zostało już zakupione.',
        paymentComplete: 'Płatność zakończona. Prosimy oczekiwać na email z numerem ubezpieczenia.',
        paymentIsProcessing: 'Twoja płatność jest w trakcie przetwarzania. Prosimy o cierpliwość.',
        registrationSuccess: 'Witamy w Clinic Hunter Cover Medical Insurance. Wysłaliśmy Ci email z zaproszeniem.',
        loginSuccess: 'Zostałeś pomyślnie zalogowany!',
        loginFailed: 'Logowanie nie powiodło się. Nie masz dostępu do Panelu. Spróbuj ponownie lub skontaktuj się z administratorem.',
        logoutSuccess: 'Zostałeś wylogowany.',
    },
    residencyModal: {
        title: 'Drodzy Klienci,',
        description1: 'Nasza oferta ubezpieczeniowa jest w trakcie aktualizacji. Sprzedaż dla osób fizycznych z Wielkiej Brytanii jest tymczasowo wstrzymana. Przepraszamy za wszelkie niedogodności.',
        description2: 'Cieszymy się, że możemy pracować nad wyspecjalizowanym produktem ubezpieczeniowym wyłącznie dla obywateli Wielkiej Brytanii. Sprzedaż dla obywateli UE pozostaje bez zmian.',
        additionalData: 'Będziemy informować o kolejnych aktualizacjach.',
    },
    countries: {
        af: 'Afganistan',
        al: 'Albania',
        dz: 'Algieria',
        ad: 'Andora',
        ao: 'Angola',
        ag: 'Antigua i Barbuda',
        ar: 'Argentyna',
        am: 'Armenia',
        au: 'Australia',
        at: 'Austria',
        az: 'Azerbejdżan',
        bs: 'Bahamy',
        bh: 'Bahrajn',
        bd: 'Bangladesz',
        bb: 'Barbados',
        by: 'Białoruś',
        be: 'Belgia',
        bz: 'Belize',
        bj: 'Benin',
        bt: 'Bhutan',
        bo: 'Boliwia',
        ba: 'Bośnia i Hercegowina',
        bw: 'Botswana',
        br: 'Brazylia',
        bn: 'Brunei',
        bg: 'Bułgaria',
        bf: 'Burkina Faso',
        bi: 'Burundi',
        cv: 'Republika Zielonego Przylądka',
        kh: 'Kambodża',
        cm: 'Kamerun',
        ca: 'Kanada',
        cf: 'Republika Środkowoafrykańska',
        td: 'Czad',
        cl: 'Chile',
        cn: 'Chiny',
        co: 'Kolumbia',
        km: 'Komory',
        cg: 'Kongo',
        cd: 'Demokratyczna Republika Konga',
        cr: 'Kostaryka',
        ci: 'Wybrzeże Kości Słoniowej',
        hr: 'Chorwacja',
        cu: 'Kuba',
        cy: 'Cypr',
        cz: 'Republika Czeska',
        dk: 'Dania',
        dj: 'Dżibuti',
        dm: 'Dominika',
        do: 'Republika Dominikańska',
        ec: 'Ekwador',
        eg: 'Egipt',
        sv: 'Salwador',
        gq: 'Gwinea Równikowa',
        er: 'Erytrea',
        ee: 'Estonia',
        sz: 'Eswatini',
        et: 'Etiopia',
        fj: 'Fidżi',
        fi: 'Finlandia',
        fr: 'Francja',
        ga: 'Gabon',
        gm: 'Gambia',
        ge: 'Gruzja',
        de: 'Niemcy',
        gh: 'Ghana',
        gr: 'Grecja',
        gd: 'Grenada',
        gt: 'Gwatemala',
        gn: 'Gwinea',
        gw: 'Gwinea Bissau',
        gy: 'Gujana',
        ht: 'Haiti',
        va: 'Watykan',
        hn: 'Honduras',
        hu: 'Węgry',
        is: 'Islandia',
        in: 'Indie',
        id: 'Indonezja',
        ir: 'Iran',
        iq: 'Irak',
        ie: 'Irlandia',
        il: 'Izrael',
        it: 'Włochy',
        jm: 'Jamajka',
        jp: 'Japonia',
        jo: 'Jordania',
        kz: 'Kazachstan',
        ke: 'Kenia',
        ki: 'Kiribati',
        kp: 'Korea Północna',
        kr: 'Korea Południowa',
        kw: 'Kuwejt',
        kg: 'Kirgistan',
        la: 'Laos',
        lv: 'Łotwa',
        lb: 'Liban',
        ls: 'Lesotho',
        lr: 'Liberia',
        ly: 'Libia',
        li: 'Liechtenstein',
        lt: 'Litwa',
        lu: 'Luksemburg',
        mg: 'Madagaskar',
        mw: 'Malawi',
        my: 'Malezja',
        mv: 'Malediwy',
        ml: 'Mali',
        mt: 'Malta',
        mh: 'Wyspy Marshalla',
        mr: 'Mauretania',
        mu: 'Mauritius',
        mx: 'Meksyk',
        fm: 'Mikronezja',
        md: 'Mołdawia',
        mc: 'Monako',
        mn: 'Mongolia',
        me: 'Czarnogóra',
        ma: 'Maroko',
        mz: 'Mozambik',
        mm: 'Mjanma (Birma)',
        na: 'Namibia',
        nr: 'Nauru',
        np: 'Nepal',
        nl: 'Holandia (Niderlandy)',
        nz: 'Nowa Zelandia',
        ni: 'Nikaragua',
        ne: 'Niger',
        ng: 'Nigeria',
        mk: 'Macedonia Północna',
        no: 'Norwegia',
        om: 'Oman',
        pk: 'Pakistan',
        pw: 'Palau',
        pa: 'Panama',
        pg: 'Papua-Nowa Gwinea',
        py: 'Paragwaj',
        pe: 'Peru',
        ph: 'Filipiny',
        pl: 'Polska',
        pt: 'Portugalia',
        qa: 'Katar',
        ro: 'Rumunia',
        ru: 'Rosja',
        rw: 'Rwanda',
        kn: 'Saint Kitts i Nevis',
        lc: 'Saint Lucia',
        ws: 'Samoa',
        sm: 'San Marino',
        st: 'Wyspy Świętego Tomasza i Książęca',
        sa: 'Arabia Saudyjska',
        sn: 'Senegal',
        rs: 'Serbia',
        sc: 'Seszele',
        sl: 'Sierra Leone',
        sg: 'Singapur',
        sk: 'Słowacja',
        si: 'Słowenia',
        sb: 'Wyspy Salomona',
        so: 'Somalia',
        za: 'Republika Południowej Afryki',
        ss: 'Sudan Południowy',
        es: 'Hiszpania',
        lk: 'Sri Lanka',
        vc: 'Saint Vincent i Grenadyny',
        ps: 'Państwo Palestyna',
        sd: 'Sudan',
        sr: 'Surinam',
        se: 'Szwecja',
        ch: 'Szwajcaria',
        sy: 'Syria',
        tj: 'Tadżykistan',
        tz: 'Tanzania',
        th: 'Tajlandia',
        tl: 'Timor Wschodni',
        tg: 'Togo',
        to: 'Tonga',
        tt: 'Trynidad i Tobago',
        tn: 'Tunezja',
        tr: 'Turcja',
        tm: 'Turkmenistan',
        tv: 'Tuvalu',
        ug: 'Uganda',
        ua: 'Ukraina',
        ae: 'Zjednoczone Emiraty Arabskie',
        gb: 'Wielka Brytania',
        us: 'Stany Zjednoczone',
        uy: 'Urugwaj',
        uz: 'Uzbekistan',
        vu: 'Vanuatu',
        ve: 'Wenezuela',
        vn: 'Wietnam',
        ye: 'Jemen',
        zm: 'Zambia',
        zw: 'Zimbabwe',
        xk: 'Kosowo',
    },
    residencyCountries: {
        at: 'Austria',
        be: 'Belgia',
        bg: 'Bułgaria',
        hr: 'Chorwacja',
        cy: 'Cypr',
        cz: 'Republika Czeska',
        dk: 'Dania',
        ee: 'Estonia',
        fi: 'Finlandia',
        fr: 'Francja',
        de: 'Niemcy',
        gr: 'Grecja',
        hu: 'Węgry',
        ie: 'Irlandia',
        is: 'Islandia',
        it: 'Włochy',
        lv: 'Łotwa',
        li: 'Liechtenstein',
        lt: 'Litwa',
        lu: 'Luksemburg',
        mt: 'Malta',
        mc: 'Monako',
        nl: 'Holandia',
        no: 'Norwegia',
        pl: 'Polska',
        pt: 'Portugalia',
        ro: 'Rumunia',
        sm: 'San Marino',
        sk: 'Słowacja',
        si: 'Słowenia',
        ch: 'Szwajcaria',
        es: 'Hiszpania',
        se: 'Szwecja',
        gb: 'Wielka Brytania - Wkrótce dostępne',
        va: 'Watykan'
    },
};

export default pl;
