import * as React from "react";
import {Form, Translation, FormControlChangeType, IFormConfig} from 'common-web';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {inquiryFormSelector, inquirySelector} from "../../../store/selectors/axaInsuranceSelectors";
import {changeCurrentStep, changeInquiryForm, changeShowFormLoader, IInquiryForm} from "../../../store/reducers/axaInsuranceSlice";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {patientEmailFormConfig} from "./patientEmailFormConfig";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {InquiryFormStep} from '../inquiryFormStep';
import {IStepManagerService} from '../../../service/stepManagerService';
import {BehaviorSubject, of, Subscription} from "rxjs";
import {tap, filter, debounceTime, catchError} from "rxjs/operators";
import {IFormConfigTranslationService} from "../../../service/formConfigTranslationService";
import styles from './styles.module.scss';
import {authTokenSelector} from "../../../store/selectors/authSelectors";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {IInquiryURLParams} from "../../../model/iInquiryURLParams";
import {IInquiryPatient, updateInquiryAsClinicAPI} from "../../../api/updateInquiryAsClinicAPI";

interface IFormStepPatientEmailProps extends RouteComponentProps {
    readonly inquiryForm: IInquiryForm;
    readonly authToken: string | null;
    readonly inquiry: any;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly changeInquiryForm: typeof changeInquiryForm;
    readonly changeShowFormLoader: typeof changeShowFormLoader;
}

interface IFormStepPatientEmailState {
    formConfig: typeof IFormConfig;
    value: any;
    isFormValid: boolean;
}

class FormStepPatientEmail extends React.Component<IFormStepPatientEmailProps, IFormStepPatientEmailState> {
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('FormConfigTranslationService') private formConfigTranslator: IFormConfigTranslationService;


    readonly stepName: InquiryFormStep = InquiryFormStep.PATIENT_EMAIL;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    private isProcessing: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            formConfig: null,
            value: null,
            isFormValid: true,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        this.setDefaultFormConfig();
        this.props.changeCurrentStep(this.stepName);
        if (this.props.inquiryForm) {
            this.updateFormFromState();
        }

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap(()  => this.isProcessing = true),
                debounceTime(500),
                tap((data: any)  => this.onFormValueChange(data.value)),
                tap(()  => this.isProcessing = false),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<IFormStepPatientEmailProps>,
        prevState: Readonly<IFormStepPatientEmailState>,
        snapshot?: any
    ): void {
        if (this.props.inquiryForm !== prevProps.inquiryForm) {
            this.updateFormFromState();
        }
    }

    componentWillUnmount() {
        this.props.changeShowFormLoader(false);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        return (
            <React.Fragment>
                <header>
                    <h2 className={'sr-only'}><Translation text={`form.axaForm.patientEmail.title`}/></h2>
                </header>
                <article>
                    <header>
                        <h3 className={styles.subtitle}><Translation text={`form.axaForm.patientEmail.subtitle`}/>
                        </h3>
                    </header>
                    <Form config={this.state.formConfig}
                          onValueStateChange={this.onValueStateChange}
                          onValidationStateChange={this.onValidationStateChange}
                          value={this.state.value}
                          controlName={'patientEmailForm'}/>

                    <footer className="button-form-container">
                        <button onClick={this.goPrev}
                                className="btn btn-prev">
                            <Translation text={`form.buttons.prev`}/>
                        </button>

                        <button className={`btn btn-next ${!this.state.isFormValid ? 'disabled' : ''}`}
                                tabIndex={!this.state.isFormValid ? -1 : 0}
                                onClick={this.goNext}>
                            <Translation text={`form.buttons.next`}/>
                        </button>
                    </footer>
                </article>
            </React.Fragment>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private goNext = () => {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        const {inquiryId} = this.props.match.params as IInquiryURLParams;

        if (this.props.authToken && inquiryId) {
            const patientData = {
                account: {
                    user: {
                        login: this.state.value.patientEmail
                    }
                }
            }
            this.editInquiry(this.props.authToken, inquiryId, patientData)
        }
    };

    private goPrev = () => {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.stepManager.goPrev(this.stepName)
    };

    // ========== CUSTOM METHODS ========== //


    private onFormValueChange = (value: any) => {
        this.props.changeInquiryForm(value);
    };

    private updateFormFromState = () => {
        const inquiry = this.props.inquiryForm,
            value: any = {
            patientEmail: inquiry.patientEmail ? inquiry.patientEmail : null,
        };

        this.setState({
            value: value,
        });
    };

    private setDefaultFormConfig = () => {
        const formConfig = patientEmailFormConfig(),
            translatedFormConfig = this.formConfigTranslator.setTranslationKeys(formConfig, this.stepName);

        this.setState({formConfig: translatedFormConfig});
    }

    private editInquiry = (authToken: string, inquiryId: string, patientData: IInquiryPatient) => {
        this.props.changeShowFormLoader(true);
        this.subscriptions.push(
            updateInquiryAsClinicAPI(authToken, this.props.inquiry.id, patientData).pipe(
                tap(() => {
                    this.props.changeShowFormLoader(false);
                    this.stepManager.goNext(this.stepName);
                }),
                catchError((error: any) => {
                    this.props.changeShowFormLoader(false);
                    this.alertManager.handleFormApiError(error);
                    return of();
                })
            ).subscribe()
        )
    }
}


export default connect(
    (state: RootState) => ({
        inquiryForm: inquiryFormSelector(state),
        authToken: authTokenSelector(state),
        inquiry: inquirySelector(state),
    }),
    {
        changeCurrentStep,
        changeInquiryForm,
        changeShowFormLoader
})(withRouter(FormStepPatientEmail));
