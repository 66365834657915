import { combineReducers } from "redux";
import saga from "./sagaSlice";
import metadataSlice from "./metadataSlice";
import axaInsurance from "./axaInsuranceSlice";
import authSlice from "./authSlice";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {accountSlice, alertSlice} from "common-web";

const history = createBrowserHistory();

const rootReducer = combineReducers({
    saga: saga,
    account: accountSlice,
    alert: alertSlice,
    auth: authSlice,
    axaInsurance: axaInsurance,
    metadata: metadataSlice,
    router: connectRouter(history)
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
