import {FormButtonType, FormControlType, InputType, fullCountryListLabels, MultiSelectType, ValidationRules, IFormConfig} from "common-web";
import moment from "moment";
import store from '../../../store';
import {inquiryFormSelector} from '../../../store/selectors/axaInsuranceSelectors';
import i18next from 'i18next';

export const treatmentFormConfig: typeof IFormConfig = {
  controlType: 'form',
  class: 'registration-form',
  dataAccessor: (data: any) => data, // data is not grouped
  outputDataMapper: (data: any, previousStateSnapshot: any) => {
    Object.assign(previousStateSnapshot, data);

    return previousStateSnapshot;
  },
  controls: [
    {
      controlType: 'group',
      key: 'destination_id',
      class: 'form-row',
      controls: {
        destinationId: {
          hostClass: 'col-100',
          controlType: 'control',
          defaultValue: null,
          placeholder: 'form.axaForm.treatment.formControls.destinationId.placeholder',
          formControlType: FormControlType.AUTOCOMPLETE,
          multiselectType: MultiSelectType.SINGLE,
          label: "form.axaForm.treatment.formControls.destinationId.label",
          isCountryListTranslated: true,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
          multiselectOptions: () => {
            const form = inquiryFormSelector(store.getState()),
                residency = form.residency,
                companionResidency = form.companionResidency;

            return fullCountryListLabels.map((country: any) => ({
              value: country.value,
              label: country.label,
              isDisabled: country.value === companionResidency || country.value === residency,
            }))
          },
          inputDataMapper: (data: any) => {
            if (!data) {
              return [];
            }

            return fullCountryListLabels
                .filter((country: any) => country.value === data)
                .map((country: any) => ({
                  value: country.value,
                  label: i18next.t(`countries.${country.label}`),
                }));
          },
          outputDataMapper: (data: any) => {
            if (Array.isArray(data)) {
              return (data.length > 0) ? data[0].value : null;
            }
            if (data && typeof data === 'object') {
              return data.value;
            }

            return data;
          },
        },
      },
    },
    {
      controlType: 'group',
      key: 'treatment_category_id',
      class: 'form-row',
      controls: {
        treatmentCategoryId: {
          hostClass: 'col-100',
          controlType: 'control',
          defaultValue: null,
          placeholder: 'form.axaForm.treatment.formControls.treatmentCategoryId.placeholder',
          formControlType: FormControlType.AUTOCOMPLETE,
          multiselectType: MultiSelectType.SINGLE,
          label: "form.axaForm.treatment.formControls.treatmentCategoryId.label",
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
          multiselectOptions: [],
            inputDataMapper: (data: any) => {
                if (!data) {
                    return [];
                }

                return treatmentFormConfig.controls[1].controls.treatmentCategoryId.multiselectOptions
                    .filter((treatmentCategory: any) => treatmentCategory.value === data)
                    .map((treatmentCategory: any) => ({
                        value: treatmentCategory.value,
                        label: treatmentCategory.label,
                    }));
            },
          outputDataMapper: (data: any) => {
            if (Array.isArray(data)) {
              return (data.length > 0) ? data[0].value : null;
            }
            if (data && typeof data === 'object') {
              return data.value;
            }

            return data;
          },
        }
      }
    },
    {
      controlType: 'group',
      key: 'clinic-name',
      class: 'form-row',
      controls: {
        clinicName: {
          controlType: 'control',
          defaultValue: '',
          hostClass: 'col-100',
          formControlType: FormControlType.INPUT,
          validationRules: [],
          placeholder: 'form.axaForm.treatment.formControls.clinicName.placeholder',
          label: "form.axaForm.treatment.formControls.clinicName.label",
          type: InputType.TEXT,
        },
      }
    },
    {
      controlType: 'group',
      key: 'departure_return_dates',
      class: 'form-row',
      groupLabel: 'form.axaForm.treatment.formControls.departure.groupLabel',
      validationRules: [
        {
          name: ValidationRules.IS_DATE_LTE_THAN,
          params: {
            valueAccessor: (value: any) => value.departure,
            compareAccessor: (value: any) => value.return}
          },
          { name: ValidationRules.IS_REQUIRED },
        ],
      controls: {
        departure: {
          hostClass: 'col-50 horizontal',
          controlType: 'control',
          defaultValue: '',
          additionalStyles: 'horizontal',
          formControlType: FormControlType.DATE,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
            {
              name: ValidationRules.IS_DATE_GTE_THAN,
              params: {
                valueAccessor: (data: any) => data,
                compareAccessor: () => moment().subtract(1,'days'),
              }
            },
          ],
          placeholder: 'form.axaForm.treatment.formControls.departure.placeholder',
          label: "form.axaForm.treatment.formControls.departure.label",
          maxDate: '2100-01-01',
          minDate: moment().format('YYYY-MM-DD'),
          openToDate: moment().format('YYYY-MM-DD'),
          type: InputType.DATE,
        },
        return: {
            hostClass: 'col-50 horizontal',
          controlType: 'control',
          defaultValue: '',
          additionalStyles: 'horizontal',
          formControlType: FormControlType.DATE,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
          placeholder: 'form.axaForm.treatment.formControls.return.placeholder',
          label: "form.axaForm.treatment.formControls.return.label",
          type: InputType.DATE,
          maxDate: '2100-01-01',
          minDate: moment().format('YYYY-MM-DD'),
        }
      }
    },
    {
      controlType: 'group',
      key: 'visit_date',
      class: 'form-row',
      controls: {
        visitDate: {
          hostClass: 'col-50',
          controlType: 'control',
          defaultValue: '',
          additionalStyles: '',
          formControlType: FormControlType.DATE,
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
          placeholder: 'form.axaForm.treatment.formControls.visitDate.placeholder',
          label: "form.axaForm.treatment.formControls.visitDate.label",
          maxDate: '2100-01-01',
          minDate: moment().format('YYYY-MM-DD'),
          openToDate: moment().format('YYYY-MM-DD'),
          type: InputType.DATE,
        },
      }
    },
    {
      controlType: 'group',
      key: 'submit_button',
      class: 'form-row',
      controls: {
        submitButton: {
          controlType: 'button',
          formControlType: FormControlType.BUTTON,
          buttonType: FormButtonType.SUBMIT,
          hostClass: 'col-100 mb-0',
          defaultContainerStyles: 'button-container mt-2',
          containerStyles: '',
          defaultValue: null,
          btnText: "form.buttons.next",
          disabledStyles: 'disabled',
          enabledStyles: '',
          isLabelHidden: true,
          buttonDisabled: (mappedOutputValue: any, isFormValid: boolean) => {
            return !isFormValid;
          }
        }
      }
    },
  ]
};
