import * as React from "react";
import {Component} from "react";
import styles from "./styles.module.scss";
import {Translation} from "common-web";

export interface IQuoteTableItem {
    name: string | null,
    label: string | null,
    data: string | null
}

export interface IQuoteListProps {
    readonly items: IQuoteTableItem[];
}

class QuoteList extends Component<IQuoteListProps, any> {
    render() {
        return <dl className={styles.quoteList}>{this.renderList()}</dl>;
    }

    private renderList() {
        return this.props.items.map((quoteItem: IQuoteTableItem, index: number) => {
            return (
                <div key={index}
                     className={`${styles.quoteListRow} ${quoteItem.name ? styles[quoteItem.name] : ''}`}>
                    <dt className={styles.quoteListItemName}><Translation text={quoteItem.label}/></dt>
                    <dd className={styles.quoteListItemValue}>{this.getData(quoteItem)}</dd>
                </div>
            )
        })
    }

    private getData(item: IQuoteTableItem) {
        if (!item.data) {
            return '-';
        }

        return item.data;
    }
}

export default QuoteList;
