import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import {createBrowserHistory} from 'history';
import {ConnectedRouter} from 'connected-react-router';
import 'date-input-polyfill';
import store, { persistor } from "./store";
import "./i18n";
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';

export const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
           <App />
        </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
