import React from 'react';
import {BasicModal, CustomCard, CustomCardType, Translation} from "common-web";
import styles from "./styles.module.scss";


interface IResidencyInformationModalProps {
    isModalShown: boolean;
    toggleResidencyInformationModal: any;
}

interface IResidencyInformationModalState {}

class ResidencyInformationModal extends React.Component<IResidencyInformationModalProps, IResidencyInformationModalState> {
    render() {
        return (
            <React.Fragment>
                <BasicModal isModalShown={this.props.isModalShown}
                            closeModal={this.props.toggleResidencyInformationModal}
                            isCustomMobileStyle={true}>
                    <CustomCard type={CustomCardType.MODAL_CARD}>
                        <CustomCard.Body>
                            <div className={styles.modalContent}>
                                <div className={styles.modalHeader}>
                                    <button className={styles.closeModalBtn}
                                            onClick={() => {this.props.toggleResidencyInformationModal()}}>
                                        <span className="feather icon-x"/>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className={styles.details}>
                                        <Translation text={'residencyModal.title'}/>
                                    </p>
                                    <p className={styles.details}>
                                        <Translation text={'residencyModal.description1'}/>
                                    </p>
                                    <p className={styles.details}>
                                        <Translation text={'residencyModal.description2'}/>
                                    </p>
                                    <p className={styles.details}>
                                        <Translation text={'residencyModal.additionalData'}/>
                                    </p>
                                </div>
                            </div>
                        </CustomCard.Body>
                    </CustomCard>
                </BasicModal>
            </React.Fragment>
        );
    }
}

export default ResidencyInformationModal;
