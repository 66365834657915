import { Observable } from "rxjs";
import { RestQueryParams } from "common-web";
import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";

export interface IInquirySubject {
    id?: string;
    firstName: string;
    lastName: string;
    birthDate: string;
}

export interface IInquiryPatient {
    account: {
        user: {
            login: string;
        }
    }
}

export function updateInquiryAsClinicAPI(
    accessToken: string,
    inquiryId: string,
    patientDetails: IInquiryPatient,
    inquirySubjects?: IInquirySubject[] | [],
): Observable<any> {
    return clinicHunterInquiryAPI.put(
        `inquiry/${inquiryId}/update_users_credentials`,
        {
            inquirySubjects: inquirySubjects ? inquirySubjects : [],
            patient: patientDetails
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`
        }
    );
}
