import {inject, injectable} from "inversify";
import store from "../store";
import {Observable, of, throwError} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators";
import {loginAPI} from "../api/loginAPI";
import {assignInquiryToUserAPI} from "../api/assignInquiryToUserAPI";
import {
    changeInquiry,
    changeFormSteps,
    changeShowFormLoader,
    changeWizardHeadStepIndex,
    resetFormAndInitialize
} from "store/reducers/axaInsuranceSlice";
import {getLoginCredentials, setAuthState, setSecret} from "store/reducers/authSlice";
import {IAlertManagerService} from "./alertManagerService";
import {registerPatientAPI} from "../api/registerPatientAPI";
import {stepsNotAuthenticated} from "../components/AxaHost/formSteps";
import {InquiryFormStep} from "../components/InquiryForm/inquiryFormStep";
import {parseToEndOfDay, parseToStartOfDay, setAccountState, userAPI, UserRole} from "common-web";
import {IStepManagerService} from "./stepManagerService";
import {updateInquiryAPI} from "../api/updateInquiryAPI";
import {InquirySubjectFactory} from "../components/InquiryForm/FormStepQuote/inquirySubjectFactory";

const jwtDecode = require("jwt-decode");
const uuid = require("uuid/v4");

export interface IAuthenticationFlowService {
    submitLogin(login: string, password: string): Observable<any>;
    submitRegistration(login: string, password: string, shouldAddSource?: boolean): Observable<any>;
    logout(): void;
}

@injectable()
class AuthenticationFlowService implements IAuthenticationFlowService {
    @inject('AlertManagerService') private alertManager: IAlertManagerService;
    @inject('StepManagerService') private stepManager: IStepManagerService;

    public submitRegistration(login: string, password: string, shouldAddSource?: boolean): Observable<any> {
        store.dispatch(changeShowFormLoader(true));
        return registerPatientAPI(login, password)
            .pipe(
                tap(() => {
                    this.alertManager.registrationSuccess();
                }),
                catchError((error) => {
                    this.alertManager.handleFormApiError(error.response);
                    store.dispatch(changeShowFormLoader(false));
                    return throwError(error);
                }),
                switchMap(() => this.submitLogin(login, password, shouldAddSource))
            )
    };

    public submitLogin(login: string, password: string, shouldAddSource?: boolean): Observable<any> {
        store.dispatch(changeShowFormLoader(true));
        const globalState = store.getState();

        return loginAPI(login, password).pipe(
            switchMap((resp: any) => {
                const authToken = resp.authToken,
                    decoded = jwtDecode(resp.authToken),
                    userRoles = decoded.roles,
                    secret = globalState.auth.secret,
                    inquiryId = globalState.axaInsurance.inquiryId;
                let o: Observable<any> = new Observable<any>();
                if (userRoles.includes(UserRole.PATIENT)) {
                    const commonObservable = userAPI(authToken).pipe(
                        tap((resp: any) => {
                            return store.dispatch(setAccountState(
                                resp.account ? {
                                    id: resp.account.id,
                                    firstName: resp.account.firstName,
                                    lastName: resp.account.lastName,
                                    birthDate: resp.account.birthdate,
                                    addressLine1: resp.account.address.addressLine1,
                                    addressLine2: resp.account.address.addressLine2,
                                    addressLine3: resp.account.address.addressLine3,
                                    postcode: resp.account.address.postcode,
                                    city: resp.account.address.city,
                                    country: resp.account.address.country,
                                    residency: resp.account.residency,
                                } : null));
                        }),
                        catchError((error) => {
                            this.alertManager.handleFormApiError(error.response);
                            return throwError(error);
                        }),
                        tap(() => store.dispatch(getLoginCredentials(login, password)))
                    );
                    if (inquiryId && authToken && secret) {
                        o = assignInquiryToUserAPI(inquiryId, authToken, secret).pipe(
                            tap((inquiry: any) => {
                                store.dispatch(setSecret(null));
                                store.dispatch(changeInquiry(inquiry));
                            }),
                            tap(() => {
                                if (shouldAddSource) {
                                    const globalState = store.getState(),
                                        inquiryForm = globalState.axaInsurance.inquiryForm,
                                        inquiry = globalState.axaInsurance.inquiry,
                                        credentials = {authToken: authToken, secret: undefined},
                                        inquirySubjectsArray = (new InquirySubjectFactory()).createSubjects(inquiryForm, inquiry);

                                    if (!inquirySubjectsArray) {
                                        return;
                                    }

                                    return updateInquiryAPI(
                                        inquiryId,
                                        credentials,
                                        parseToStartOfDay(inquiryForm.departure),
                                        parseToEndOfDay(inquiryForm.return),
                                        inquiryForm.treatmentCategoryId as string,
                                        inquiryForm.destinationId as string,
                                        inquirySubjectsArray,
                                        inquiryForm.cid ? inquiryForm.cid : null,
                                        inquiryForm.clinicName ? inquiryForm.clinicName : null,
                                        inquiryForm.patientEmail ? inquiryForm.patientEmail : null,
                                        inquiryForm.visitDate ? inquiryForm.visitDate : null,
                                        inquiryForm.source ? inquiryForm.source : null,
                                        true,
                                        null,
                                    ).pipe(
                                        catchError((error) => {
                                            this.alertManager.handleFormApiError(error.response);
                                            return throwError(error);
                                        }),
                                        tap((resp: any) => {
                                            this.alertManager.addAlert('Inquiry data updated');
                                            store.dispatch(setSecret(null));
                                            store.dispatch(changeInquiry(resp));
                                        }),
                                    ).subscribe()
                                }
                            }),
                            catchError((error) => {
                                this.alertManager.handleFormApiError(error.response);
                                return throwError(error);
                            }),
                            switchMap(() => commonObservable)
                        )
                    } else {
                        o = commonObservable;
                    }
                    this.alertManager.loginSuccess();
                    return o;
                } else {
                    store.dispatch(changeShowFormLoader(false));
                    this.alertManager.loginFailure();
                    return of();
                }
            }),
            catchError((error) => {
                this.alertManager.handleFormApiError(error.response);
                store.dispatch(changeShowFormLoader(false));
                return throwError(error);
            }),
            tap(() => store.dispatch(changeShowFormLoader(false)))
        );
    };

    public logout() {
        this.alertManager.logoutSuccess();
        store.dispatch(resetFormAndInitialize());
        store.dispatch(setAccountState(null));
        store.dispatch(changeFormSteps(Array.from(stepsNotAuthenticated)));
        store.dispatch(changeWizardHeadStepIndex(0));
        this.stepManager.goTo(InquiryFormStep.TREATMENT);

        return store.dispatch(setAuthState(
            null,
            null,
            null,
            uuid(),
            false
        ));
    };
}

export default AuthenticationFlowService;
