import * as React from "react";
import InfoPanel from "../InfoPanel";
import InquiryForm from '../InquiryForm';
import styles from "./styles.module.scss";
import {connect} from "react-redux";
import {isAuthenticatedSelector, Toast, AuthUserMenu} from 'common-web';
import {authTokenSelector} from "../../store/selectors/authSelectors";
import {setAuthState, setSecret} from "store/reducers/authSlice";
import {Redirect, Route, Switch} from 'react-router';
import {InquiryFormStep} from '../InquiryForm/inquiryFormStep';
import {changeFormSteps, changeIsClinicHost} from "../../store/reducers/axaInsuranceSlice";
import {RouteComponentProps} from "react-router-dom";
import {stepsNotAuthenticated} from "./formSteps"
import {formStepsSelector, showFormLoaderSelector} from '../../store/selectors/axaInsuranceSelectors';
import {fixInjectedProperties, lazyInject} from "../../ioc";
import {IAuthenticationFlowService} from "../../service/authenticationFlowService";
import {WithTranslation, withTranslation} from 'react-i18next';
import {activeLanguageSelector} from "../../store/selectors/sagaSelectors";
import {LanguageLocale} from "../../constants/locales";
import LanguageSelector from "../LanguageSelector";

interface IAxaHostProps extends RouteComponentProps, WithTranslation {
    readonly authToken: string | null;
    readonly formSteps: InquiryFormStep[];
    readonly showFormLoader: boolean;
    readonly isAuthenticated: boolean;
    readonly activeLanguage: LanguageLocale;
    readonly setAuthState: typeof setAuthState;
    readonly changeFormSteps: typeof changeFormSteps;
    readonly changeIsClinicHost: typeof changeIsClinicHost;
    readonly setSecret: typeof setSecret;
}

interface IAxaHostState {
}

class AxaHost extends React.Component<IAxaHostProps, IAxaHostState> {
    @lazyInject("AuthenticationFlowService")

    private authenticationFlowService: IAuthenticationFlowService;

    constructor(props: any) {
        super(props);
        fixInjectedProperties(this);

        // need to load secret before mounting children
        const inquirySecret = new URLSearchParams(this.props.location.search).get('s');
        if (inquirySecret) {
            this.props.setSecret(inquirySecret);
        }
    }

    componentDidMount(): void {
        this.props.changeIsClinicHost(false);
        this.props.changeFormSteps(Array.from(stepsNotAuthenticated));
    }

    componentDidUpdate(prevProps: Readonly<IAxaHostProps>, prevState: Readonly<IAxaHostState>, snapshot?: any) {
        if (this.props.activeLanguage !== prevProps.activeLanguage) {
            const location = this.props.location.pathname,
                index = location.indexOf('/', location.indexOf('/') + 1),
                newUrl = location.substring(index);
            this.props.history.replace({ pathname: `/${this.props.activeLanguage}${newUrl}`})
        }
    }

    render() {
        if (!this.props.formSteps.length) {
            return null;
        }

        const lng = this.props.activeLanguage;

        return (
            <section className={styles.axaHostSection}>
                <InfoPanel/>
                <div className={styles.formContainer}>
                    <AuthUserMenu styles={styles}
                                  authenticationFlow={this.authenticationFlowService}
                                  isLoading={this.props.showFormLoader}
                                  languageComponent={<LanguageSelector />}
                                  envData={process.env}/>
                    <Switch>
                        <Route path={`/:lng/inquiry/:step/:inquiryId`} component={InquiryForm} isClinicHost={false}/>
                        <Route path={`/:lng/inquiry/:step`} component={InquiryForm} isClinicHost={false}/>
                        <Route component={() => (<Redirect to={`/${lng}/inquiry/${InquiryFormStep.TREATMENT}`}/>)}/>
                    </Switch>
                </div>
                <Toast/>
            </section>
        );
    }

}

export default connect(
    (state: any) => ({
        authToken: authTokenSelector(state),
        formSteps: formStepsSelector(state),
        showFormLoader: showFormLoaderSelector(state),
        isAuthenticated: isAuthenticatedSelector(state),
        activeLanguage: activeLanguageSelector(state),
    }),
    {
        setAuthState,
        setSecret,
        changeFormSteps,
        changeIsClinicHost,
    }
)(withTranslation()(AxaHost));
