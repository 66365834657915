import * as React from "react";
import {connect} from "react-redux";
import {changeCurrentStep} from '../../../store/reducers/axaInsuranceSlice';
import {InquiryFormStep} from '../inquiryFormStep';
import CheckoutForm from './CheckoutForm';

interface IFormStepPaymentProps {
    readonly changeCurrentStep: typeof changeCurrentStep;
}

class IFormStepPaymentState {
}

class FormStepPayment extends React.Component<IFormStepPaymentProps, IFormStepPaymentState> {
    readonly stepName: InquiryFormStep = InquiryFormStep.PAYMENT;

    componentDidMount() {
        this.props.changeCurrentStep(this.stepName);
    }

    render() {
        return (
            <CheckoutForm />
        )
    }
}

export default connect(
    () => ({}),
    {
        changeCurrentStep,
    }
)(FormStepPayment);

