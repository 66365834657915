import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import {StripeCardElement} from '@stripe/stripe-js';
import {InquiryFormStep} from '../../inquiryFormStep';
import './styles.scss';
import {Form, FormControlChangeType, IFormConfig, Translation} from 'common-web';
import {paymentFormConfig} from '../paymentFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {authTokenSelector} from '../../../../store/selectors/authSelectors';
import {
    inquiryFormSelector,
    inquiryIdSelector,
    inquirySelector
} from '../../../../store/selectors/axaInsuranceSelectors';
import {
    changeInquiryForm,
    changeShowFormLoader,
    IInquiryForm,
    changeInquiry
} from '../../../../store/reducers/axaInsuranceSlice';
import {IStepManagerService} from '../../../../service/stepManagerService';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {acceptInquiry} from '../../../../api/acceptInqury';
import {getPaymentSecret} from '../../../../api/getPaymentSecret';
import {getInquiryAsLoggedAPI} from '../../../../api/getInquiry';
import {BehaviorSubject, Subscription, of} from "rxjs";
import {catchError, switchMap, tap, filter, debounceTime} from "rxjs/operators";
import {Observable} from "rxjs";


export interface ICheckoutFormProps {
    readonly inquiryForm: IInquiryForm;
    readonly inquiryId: string | null;
    readonly inquiry: any;
    readonly authToken: string;
    readonly changeShowFormLoader: typeof changeShowFormLoader;
    readonly changeInquiryForm: typeof changeInquiryForm;
    readonly changeInquiry: typeof changeInquiry;
}

export interface ICheckoutFormState {
    readonly formConfig: typeof IFormConfig;
    readonly value: any;
    readonly isFormValid: boolean;
    readonly isStripeFormValid: boolean;
    readonly stripePresent: boolean;
}

class CheckoutForm extends React.Component<ICheckoutFormProps, ICheckoutFormState> {
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    readonly stepName: InquiryFormStep = InquiryFormStep.PAYMENT;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    private isProcessing: boolean = false;
    private stripe: any;
    private elements: any;

    constructor(props: any) {
        super(props);

        fixInjectedProperties(this);

        this.state = {
            formConfig: paymentFormConfig,
            value: null,
            isFormValid: false,
            isStripeFormValid: false,
            stripePresent: false,
        }
    }

    componentDidMount() {
        if (this.props.inquiryForm) {
            this.updateFormFromState();
        }

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap(()  => this.isProcessing = true),
                debounceTime(500),
                tap((data: any)  => this.onFormValueChange(data.value)),
                tap(()  => this.isProcessing = false),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<ICheckoutFormProps>,
        prevState: Readonly<ICheckoutFormState>,
        snapshot?: any
    ): void {
        if (this.props.inquiryForm !== prevProps.inquiryForm) {
            this.updateFormFromState();
        }
    }

    componentWillUnmount() {
        this.props.changeShowFormLoader(false);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const isFormDisabled = !this.state.isFormValid && !this.state.isStripeFormValid;
        return (
            <React.Fragment>
                <Form config={this.state.formConfig}
                      controlName={'paymentForm'}
                      onValueStateChange={this.onValueStateChange}
                      onValidationStateChange={this.onValidationStateChange}
                      value={this.state.value}/>

                <footer className="button-form-container">
                    <button onClick={() => this.stepManager.goTo(InquiryFormStep.QUOTE)} // toDo fix account array issue
                            className="btn btn-prev">
                        <Translation text={`form.buttons.prev`}/>
                    </button>
                    <button onClick={this.onFormSubmit}
                            className={`btn btn-next ${isFormDisabled ? 'disabled' : ''}`}>
                        <Translation text={`form.buttons.buy`}/>
                    </button>
                </footer>
            </React.Fragment>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.isProcessing = true;
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private goPrev = () => {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.stepManager.goPrev(this.stepName)
    };

    // ========== CUSTOM METHODS ========== //

    private updateFormFromState = () => {
        const inquiry = this.props.inquiryForm;
        this.setState({
            value: {
                cardholderName: inquiry.cardholderName,
                agreeToConditions: inquiry.agreeToConditions,
                specialTermsAndConditions: inquiry.specialTermsAndConditions,
                needsAndRequirements: inquiry.needsAndRequirements,
                distanceContracts: inquiry.distanceContracts,
            }
        })
    };

    private onFormSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isProcessing || !this.props.inquiry) {
            return;
        }
        this.isProcessing = true;
        this.props.changeShowFormLoader(true);
        this.alertManager.paymentProcessing();

        if (this.props.inquiry?.acceptedOffer?.payments && this.props.inquiry.acceptedOffer.payments.length &&
            this.props.inquiry.acceptedOffer.payments[0].id) {
            this.subscriptions.push(this.confirmPaymentFlow(this.props.inquiry).subscribe())
        } else {
            this.subscriptions.push(
                this.acceptInquiryFlowObservable().subscribe()
            );
        }
    };

    private acceptInquiryFlowObservable(): Observable<any> {
        return acceptInquiry(this.props.inquiry.id, this.props.inquiry.offers[0].id, this.props.authToken).pipe(
            catchError((error: any) => {
                this.handleError(error);
                return of();
            }),
            switchMap(() => getInquiryAsLoggedAPI(this.props.inquiryId as string, this.props.authToken)),
            tap((acceptInquiryResp) => {
                this.props.changeInquiry(acceptInquiryResp);
                return this.subscriptions.push(
                    this.confirmPaymentFlow(acceptInquiryResp).subscribe()
                );
            }),
        );
    }


    private confirmPaymentFlow(inquiry: any): Observable<any> {
        let acceptedPaymentId = inquiry.acceptedOffer.payments[0].id;

        return getPaymentSecret(acceptedPaymentId, this.props.authToken).pipe(
            switchMap((getPaymentSecretResp: any) => {
                const confirmCardPaymentPayload = {
                    payment_method: {
                        card: this.elements.getElement(CardElement) as StripeCardElement,
                        billing_details: {
                            name: this.state.value.cardholderName,
                        },
                    }
                };
                return this.stripe.confirmCardPayment(
                    getPaymentSecretResp.client_secret,
                    confirmCardPaymentPayload
                )
            }),
            catchError((error: any) => {
                this.handleError(error);
                return of();
            }),

            switchMap((paymentResponse: any) => {
                if (paymentResponse.error) {
                    let errorMessage = paymentResponse.error && paymentResponse.error.message ? paymentResponse.error.message :
                        'Stripe: payment failed. Contact with our support.';
                    this.alertManager.addAlert(errorMessage, null, null, false);
                    this.props.changeShowFormLoader(false);
                    return of();
                } else {
                    this.alertManager.handlePaymentSuccess();
                    this.props.changeShowFormLoader(false);
                    this.stepManager.goNext(this.stepName);
                    return of();
                }
            })
        );
    }

    private handleError(error: any): void {
        this.alertManager.handleFormApiError(error.response);
        this.props.changeShowFormLoader(false);
    }

    private onFormValueChange = (value: any) => {
        const mappedValue: any = {};
        Object.keys(value).forEach((key: string) => {
            let fieldValue = value[key];
            if (undefined === fieldValue || null === fieldValue) {
                mappedValue[key] = null;

                return;
            }
            if (key === 'stripecard') {
                fieldValue = Object.assign({}, fieldValue);
                this.stripe = fieldValue['stripe'];
                this.elements = fieldValue['elements'];
                delete fieldValue['stripe'];
                delete fieldValue['elements'];
            } else {
                mappedValue[key] = fieldValue;
            }
        });

        this.props.changeInquiryForm(mappedValue);
    };

}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        inquiryForm: inquiryFormSelector(state),
        inquiryId: inquiryIdSelector(state),
        inquiry: inquirySelector(state),
    }),
    {
        changeShowFormLoader,
        changeInquiryForm,
        changeInquiry
    }
)(CheckoutForm);
