import React, {Component} from 'react';
import {MultiSelect, MultiSelectType} from "common-web";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {changeActiveLanguage} from 'store/reducers/sagaSlice';
import {LanguageLocale} from "../../constants/locales";
import i18n from 'i18n';
import {activeLanguageSelector} from "../../store/selectors/sagaSelectors";

const languageOptions = [
    {value: LanguageLocale.EN, label: 'en'},
    {value: LanguageLocale.PL, label: 'pl'},
];

interface IConnectedLanguageSelectorProps {
    readonly activeLanguage: LanguageLocale;
    readonly changeActiveLanguage: typeof changeActiveLanguage;
}

interface ILanguageSelectorProps extends IConnectedLanguageSelectorProps,
    WithTranslation {
}

interface ILanguageSelectorState {
    selectedValue: {[key: string]: any} | null;
}

class LanguageSelector extends Component<ILanguageSelectorProps, ILanguageSelectorState> {
    constructor(props: ILanguageSelectorProps) {
        super(props);
        this.state = {
            selectedValue: null
        }
    }

    componentDidMount() {
        const lng = this.props.activeLanguage,
            controlValue = languageOptions.find((item) => item.value === lng);
        if (undefined !== controlValue) {
            this.setState({selectedValue: controlValue});
        }
    }

    componentDidUpdate(prevProps: Readonly<ILanguageSelectorProps>, prevState: Readonly<ILanguageSelectorState>, snapshot?: any) {
        if (this.props.activeLanguage !== prevProps.activeLanguage) {
            const lng = this.props.activeLanguage,
                controlValue = languageOptions.find((item) => item.value === lng);
            if (undefined !== controlValue) {
                this.setState({selectedValue: controlValue});
            }
        }
    }

    render() {
        return (
            <div className="language-select">
                {this.renderLanguageControl()}
            </div>
        );
    }


    private renderLanguageControl = () => {
        const {t} = this.props;

        return (
            <MultiSelect
                multiselectType={MultiSelectType.SINGLE}
                handleChange={(e: any) => this.onLanguageChange(e)}
                options={languageOptions}
                name={'select_clinics'}
                placeholder={t('subscriptionPlans.list.order')}
                value={this.state.selectedValue}
                isComponentCustom={true}
                isLanguageSelect={true}
            />
        )
    };

    private onLanguageChange = (lng: {[key: string]: any}) => {
        const selectedLanguage = lng.value;
        this.props.changeActiveLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
        this.setState({selectedValue: lng});
    };

}

export default withTranslation()(connect(
    (state: RootState) => ({
        activeLanguage: activeLanguageSelector(state)
    }),
    {
        changeActiveLanguage,
    }
)(LanguageSelector));
