import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import {IMetaDataState} from "../reducers/metadataSlice";

export const selectMetadata = (state: RootState): IMetaDataState => {
  return state.metadata;
};

export const treatmentCategoriesSelector = createSelector(
  [selectMetadata],
  (state: IMetaDataState) => state.treatmentCategories
);

export const metadataLoadingSelector = createSelector(
  [selectMetadata],
  (state: IMetaDataState) => state.isLoading
);

export const metadataErrorSelector = createSelector(
  [selectMetadata],
  (state: IMetaDataState) => state.error
);
