import {fullCountryList} from "../fullCountryList";
import {IQuoteTableItem} from "./QuoteList";
import {IInquiryForm} from "../../../store/reducers/axaInsuranceSlice";
import {countryList} from "../countryList";
import {formatServerDateToString} from "common-web";

export class QuoteTableConfigFactory {
    create(inquiryForm: IInquiryForm, apiData: any): IQuoteTableItem[] {
        const destinationNameFromList = fullCountryList.find(country => country.value === apiData.destination),
            globalStateForm = inquiryForm,
            mainPatient = apiData.inquirySubjects.filter((subject: any) => subject.main === true)[0],
            patientBirthDate = formatServerDateToString(mainPatient.birthDate),
            patientCountryFromList = fullCountryList.find(country => country.value === mainPatient.address.country);

        const dataFromApi = {
            destination: destinationNameFromList ? destinationNameFromList.label : '',
            name: mainPatient.firstName,
            lastName: mainPatient.lastName,
            treatmentCategory: apiData.treatmentCategory.name,
            departure: formatServerDateToString(apiData.from),
            return: formatServerDateToString(apiData.to),
            quote: apiData.offers[0].premium.amount,
            street: mainPatient.address.addressLine1,
            postCode: mainPatient.address.postcode,
            city: mainPatient.address.city,
            country: patientCountryFromList ? patientCountryFromList.label : ''
        };
        let quoteTableConfig: IQuoteTableItem[] = [
            {
                name: 'name',
                label: 'form.axaForm.quote.name.label',
                data: `${dataFromApi.name} ${dataFromApi.lastName}`
            },
            {
                name: 'address',
                label: 'form.axaForm.quote.address.label',
                data: `${dataFromApi.street}, ${dataFromApi.postCode} ${dataFromApi.city}, ${dataFromApi.country}`
            },
            {
                name: 'birthDate',
                label: 'form.axaForm.quote.birthDate.label',
                data: patientBirthDate
            },
            {
                name: 'destination',
                label: 'form.axaForm.quote.destination.label',
                data: dataFromApi.destination ? dataFromApi.destination : '',
            },
            {
                name: 'treatment',
                label: 'form.axaForm.quote.treatment.label',
                data: dataFromApi.treatmentCategory ? dataFromApi.treatmentCategory : ''
            },
            {
                name: 'departure',
                label: 'form.axaForm.quote.departure.label',
                data: formatServerDateToString(dataFromApi.departure),
            },

            {
                name: 'return',
                label: 'form.axaForm.quote.return.label',
                data: formatServerDateToString(dataFromApi.return)
            },
            {
                name: 'quote',
                label: 'form.axaForm.quote.quote.label',
                data: dataFromApi.quote ? (dataFromApi.quote / 100).toFixed(2) : '-'
            },
        ];
        if (globalStateForm.addCompanion) {
            let companionResidency = countryList.filter((country: any) => country.value === globalStateForm.companionResidency)
                .map((selectedCountry: any) => selectedCountry.label)
                .join();

            const companion: IQuoteTableItem = {
                name: `companion`,
                label: `Companion`,
                data: `${globalStateForm.companionName} ${globalStateForm.companionLastName}, born: ${formatServerDateToString(globalStateForm.companionBirthDate)}, residency: ${companionResidency}`
            };
            quoteTableConfig.splice((quoteTableConfig.length - 2), 0, companion);
        }

        return quoteTableConfig;
    }
}
