import { createSlice } from "redux-starter-kit";
import { LanguageLocale } from "../../constants/locales";

export interface ISagaState {
  activeLanguage: LanguageLocale;
}

export interface IChangeLanguage {
  readonly payload: {
    activeLanguage: LanguageLocale;
  };
}

const initialState: ISagaState = {
  activeLanguage: LanguageLocale.EN
};

const sagaSlice = createSlice({
  slice: "saga",
  initialState: initialState,
  reducers: {
    changeActiveLanguage: {
      reducer: (state: ISagaState, action: IChangeLanguage) => {
        return {
          activeLanguage: action.payload.activeLanguage
        }
      },
      prepare(activeLanguage: LanguageLocale) {
        return {
          payload: { activeLanguage: activeLanguage }
        };
      }
    }
  }
});
export const {
  changeActiveLanguage
} = sagaSlice.actions;
export default sagaSlice.reducer;
