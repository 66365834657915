import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {clinicHunterRegistrationAPI} from "./provider/clinicHunterRegistration";

export function registerPatientAPI(
    login: string,
    plainPassword: string,
): Observable<any> {
    return clinicHunterRegistrationAPI.post(
        "register-patient",
        {
            login: login,
            plainPassword: plainPassword,
        },
        new RestQueryParams()
    );
}
