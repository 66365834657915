import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";
import {Observable} from "rxjs";
import {RestQueryParams} from './base/queryParams';

export function acceptInquiry(inquiryId: string, offerId: string, accessToken: string): Observable<any> {
    return clinicHunterInquiryAPI.put(
        `inquiry/${inquiryId}/set_accepted_offer`,
        {
            acceptedOfferId: offerId
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
        },
    );
}
