import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";
import {Observable} from "rxjs";
import {RestQueryParams} from "./base/queryParams";
import {IInquirySubject} from "../model/iInquirySubject";

export function updateInquiryAPI(
    inquiryId: string,
    credentials: {
        authToken: string | undefined,
        secret: string | undefined,
    },
    from: Date,
    to: Date,
    treatmentCategoryId: string,
    destinationId: string,
    inquirySubjects: IInquirySubject[],
    clinicId: string | null,
    clinicName: string | null,
    patientEmail: string | null,
    visitDate: string | null,
    source: string | null,
    lifeThreat: boolean = true,
    reference: null = null,
): Observable<any> {
    const payload: any = {
        lifeThreat: lifeThreat,
        inquirySubjects: inquirySubjects,
        reference: reference,
        from: from,
        to: to,
        treatmentCategoryId: treatmentCategoryId,
        destination: destinationId,
        clinicName: clinicName,
        patientEmail: patientEmail,
        visitDate: visitDate,
        source: source,
    };
    let headers = undefined;
    if (undefined !== credentials.authToken) {
        headers = {
            Authorization: `Bearer ${credentials.authToken}`,
        };
    } else {
        headers = {
            'X-Inquiry-Secret': credentials.secret,
        };
    }
    return clinicHunterInquiryAPI.put(
        `inquiry/${inquiryId}/update`,
        payload,
        new RestQueryParams(),
        headers
    );
}
