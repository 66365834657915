import {inject, injectable} from 'inversify';
import {InquiryFormStep} from '../components/InquiryForm/inquiryFormStep';
import {history} from '../index'
import store from '../store'
import {IFormValidatorService} from './formValidatorService';
import {changeWizardHeadStepIndex} from '../store/reducers/axaInsuranceSlice';
import {LanguageLocale} from "../constants/locales";

export interface IStepManagerService {
    goNext(currentStep: InquiryFormStep): void;
    goPrev(currentStep: InquiryFormStep): void;
    goTo(stepName: InquiryFormStep): any;
    getWizardSteps(): any[];
    manageWizardHeadNo(): void;
    getStepIndex(step: InquiryFormStep): number;
}

@injectable()
class StepManagerService implements IStepManagerService {

    @inject('FormValidatorService') private formValidator: IFormValidatorService;

    history: any;

    constructor() {
        this.history = history;
    }

    public goNext(currentStep: InquiryFormStep): void {
        const state = store.getState();
        let stepArray = Array.from(state.axaInsurance.formSteps);
        const currentStepIndex = stepArray.findIndex(step => step === currentStep);

        if (currentStepIndex === stepArray.length - 1) {
            console.log('it is a last step.  You cannot go further.');
            return;
        }
        this.goTo(stepArray[currentStepIndex + 1]);
    }

    public goPrev(currentStep: InquiryFormStep): void {
        const state = store.getState();
        let stepArray = Array.from(state.axaInsurance.formSteps);
        const currentStepIndex = stepArray.findIndex(step => step === currentStep);
        if (currentStepIndex === 0) {
            console.log('it is a first step. Cannot go back.');
            return;
        }
        this.goTo(stepArray[currentStepIndex - 1]);
    }

    public goTo(stepName: InquiryFormStep, language?: LanguageLocale): any {
        const state = store.getState(),
            selectedLanguage = state.saga.activeLanguage;

        const path: string[] = [''];
        // add clinic host if hosted by Clinic Panel
        if (state.axaInsurance.isClinicHost) {
            path.push('c');
        }

        if (!state.axaInsurance.isClinicHost) {
            path.push(selectedLanguage);
        }

        // add base host
        path.push('inquiry');

        // add language

        // logged in user can't access Account Step
        path.push(stepName);

        // add inquiryId if exists
        if (state.axaInsurance.inquiryId) {
            path.push(state.axaInsurance.inquiryId);
        }

        this.history.push(path.join('/'));
    }

    public getWizardSteps(): any[] {
        const state = store.getState();

        const stepsObj: any[] = [];
        state.axaInsurance.formSteps.forEach((stepName: InquiryFormStep, i: number) => {
            if (stepName === InquiryFormStep.SUCCESS) {
                return;
            }

            const stepHeadIndex = state.axaInsurance.wizardHeadStepIndex;
            const stepIndex = this.getStepIndex(stepName);

            // step enabled max to headStep and only if previous step is valid
            const enabled = (i <= stepHeadIndex) && (stepsObj.length ? stepsObj[stepsObj.length - 1].isEnabled : true);

            // step is enabled and valid
            const valid = this.formValidator.isStepValid(stepName);

            stepsObj.push({
                name: stepName,
                isActive: stepName === state.axaInsurance.currentStep,
                isEnabled: enabled,
                isValid: valid,
                isHead: stepHeadIndex === stepIndex,
            });
        });

        return stepsObj;
    }

    public manageWizardHeadNo(): void {
        const axaInsurance = store.getState().axaInsurance;
        const isCurrentStepValid = this.formValidator.isStepValid(axaInsurance.currentStep);
        const currentStepIndex = this.getStepIndex(axaInsurance.currentStep);

        if (currentStepIndex > axaInsurance.wizardHeadStepIndex || !isCurrentStepValid) {
            store.dispatch(changeWizardHeadStepIndex(currentStepIndex));
        }
    }

    public getStepIndex(stepName: InquiryFormStep): number {
        const state = store.getState();

        const stepArray = Array.from(state.axaInsurance.formSteps);

        const index = stepArray.findIndex((step: InquiryFormStep) => step === stepName);
        if (index === -1) {
            throw new Error('[StepManagerService]: Invalid step index.')
        }

        return index;
    }
}

export default StepManagerService;
